import { createFileRoute } from '@tanstack/react-router';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { validateRoute } from 'router/util';

export const Route = createFileRoute('/a/cadastro/cliente')({
    beforeLoad: () =>
        validateRoute({
            permissao: 'upp_cadastro_cliente',
            nivel: TipoPermissao.Parcial,
            podeExibirFintech: true,
        }),
});
