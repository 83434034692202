import dayjs from 'dayjs';
import { UsuarioPerfilTipoNum } from 'features/usuario/types/UsuarioEmpresa';
import { TipoPermissao, type UsuarioPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { DATE_TIME_FORMAT } from 'std/const/date';
import { useLoginStore } from './store';

const perfisAdm: UsuarioPerfilTipoNum[] = [
    UsuarioPerfilTipoNum.Administrador,
    UsuarioPerfilTipoNum.Gerente,
];

export function isUserAdmin(): boolean {
    const use_usuario_tipo_idpk = useLoginStore
        .getState()
        .getUsuarioEmpresa()?.use_usuario_tipo_idpk;

    return !!use_usuario_tipo_idpk && perfisAdm.includes(use_usuario_tipo_idpk);
}

// TODO: ver se podemos remover isso no futuro
export function canUserListAllVendedores(): boolean {
    return isUserAdmin();
}

// TODO: ver se podemos remover isso no futuro
export function canUserListAllEntregadores(): boolean {
    return isUserAdmin();
}

export function getUserPermission(permissao: UsuarioPermissao): TipoPermissao {
    const usuario_tipo_permissao = useLoginStore
        .getState()
        .getUsuarioEmpresa()?.usuario_tipo_permissao;

    return usuario_tipo_permissao?.[permissao] || TipoPermissao.Bloqueado;
}

export function userHasPermission(
    permissao: UsuarioPermissao,
    nivel: TipoPermissao.Completo | TipoPermissao.Parcial,
): boolean {
    const userPermission = getUserPermission(permissao);

    if (nivel === TipoPermissao.Completo) {
        return userPermission === TipoPermissao.Completo;
    }

    return userPermission === TipoPermissao.Completo || userPermission === TipoPermissao.Parcial;
}

export function getTokenStatus(): 'valid' | 'about-to-expire' | 'expired' {
    const token_exp = useLoginStore.getState().login?.token_exp;

    if (!token_exp) {
        return 'expired';
    }

    const now = dayjs();
    const tokenExp = dayjs(token_exp, DATE_TIME_FORMAT);
    const diff = tokenExp.diff(now, 'minutes');

    if (diff <= 0) {
        return 'expired';
    }

    // Faltando 5 horas renovar o token
    if (diff <= 5 * 60) {
        return 'about-to-expire';
    }

    return 'valid';
}

export function canShowExtratoTemporario(keys: number[]): boolean {
    const empresa = useLoginStore.getState().getUsuarioEmpresa()?.empresa[0];

    if (!empresa) {
        return false;
    }

    return keys.includes(empresa.emp_fintech_extrato_temporario);
}
