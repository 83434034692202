import { EmpresaSchema } from 'features/empresa/types/Empresa';
import { z } from 'zod';
import { UsuarioSchema } from './Usuario';
import { UsuarioTipoPermissaoSchema } from './UsuarioTipoPermissao';

export enum UsuarioEmpresaStatus {
    Pendente = 'P',
    Ativo = 'A',
    Bloqueado = 'B',
}

export enum UsuarioPerfilTipoNum {
    Administrador = 1,
    Gerente = 2,
    Comprador = 3,
    Financeiro = 4,
    FinanceiroAvancado = 5,
    Vendedor = 6,
    VendedorAvancado = 7,
    AplicativoIntegrado = 8,
}

export enum UsuarioPerfilTipoStr {
    Administrador = 'Administrador',
    Gerente = 'Gerente',
    Comprador = 'Comprador',
    Financeiro = 'Financeiro',
    FinanceiroAvancado = 'Financeiro Avançado',
    Vendedor = 'Vendedor',
    VendedorAvancado = 'Vendedor Avançado',
    AplicativoIntegrado = 'Aplicativo Integrado',
}

export const UsuarioEmpresaSchema = z.object({
    /** O array de um item só */
    empresa: z.array(EmpresaSchema),
    usuario: UsuarioSchema,
    usuario_tipo_permissao: UsuarioTipoPermissaoSchema.nullable(),
    usuario_perfil_tipo: z.nativeEnum(UsuarioPerfilTipoStr).nullable(),

    use_idpk: z.number(),
    use_usuario_idpk: z.number(),
    use_empresa_idpk: z.number(),
    use_status: z.nativeEnum(UsuarioEmpresaStatus),
    use_autenticar: z.enum(['S', 'N']),
    use_vendedor: z.enum(['S', 'N']),
    use_entregador: z.enum(['S', 'N']),
    use_usuario_tipo_idpk: z.nativeEnum(UsuarioPerfilTipoNum).nullable(),
    use_tabela_preco_idpk: z.number().nullable(),
    use_tabela_comissao_idpk: z.number().nullable(),
    use_entregador_tabela_comissao_idpk: z.number().nullable(),
    use_ultima_alteracao: z.string().nullable(),
});

export type UsuarioEmpresa = z.infer<typeof UsuarioEmpresaSchema>;

export const UsuarioEmpresaSemUsuarioSchema = UsuarioEmpresaSchema.omit({ usuario: true });
export type UsuarioEmpresaSemUsuario = z.infer<typeof UsuarioEmpresaSemUsuarioSchema>;
