import { downloadTextFile } from 'std/util';

/** @deprecated */
export function registrosToCsv<Registro>(
    registros: Registro[],
    fileName: string,
    keys?: string[],
    extra?: string[],
) {
    let blobArray = convertObjectArrayToCSV(registros, keys);

    if (extra) {
        blobArray = blobArray.concat(extra);
    }

    downloadTextFile(blobArray, `${fileName}.csv`);
}

/** @deprecated */
export type CsvData = {
    fileName: string;
    tableNames: string[];
    tables: unknown[][] | null;
};

/** @deprecated */
export function multiRegistrosToCsv(csvData: CsvData, keys?: string[]) {
    if (!csvData?.tables) {
        return;
    }

    let blobArray: string[] = [];

    for (const [idx, table] of csvData.tables.entries()) {
        blobArray.push(csvData.tableNames[idx] || '');
        blobArray.push('\n');
        blobArray = blobArray.concat(convertObjectArrayToCSV(table, keys));
    }

    downloadTextFile(blobArray, csvData.fileName);
}

/** @deprecated */
function convertObjectArrayToCSV<Registro>(registros: Registro[], keys?: string[]): string[] {
    if (!registros.length) {
        return [''];
    }

    // gerando lista de chaves de Registro
    let chaves: string[];

    // caso uma lista de chaves tenha sido fornecida
    if (keys) {
        chaves = keys;
    } else {
        const obj = {};
        const firstRegistro = registros[0] as Registro;
        Object.assign(obj, firstRegistro);
        chaves = keys || Object.keys(obj);
    }

    const blobArray: string[] = [];

    // linha titulo do csv
    blobArray.push(chaves.map((chave) => chave.toUpperCase()).join(';'));
    blobArray.push('\n');

    // cada registro da array vira uma linha
    for (const registro of registros) {
        const tempArr: string[] = [];

        for (const chave of chaves) {
            let addItem = registro[chave];

            if (typeof registro[chave] === 'number') {
                addItem = `"${registro[chave].toLocaleString('pt-BR')}"`;
            } else if (addItem && typeof addItem === 'string' && addItem.includes(',')) {
                addItem = `"${registro[chave]}"`;
            }

            if (typeof addItem === 'object') {
                addItem = JSON.stringify(addItem);
            }

            tempArr.push(addItem);
        }

        blobArray.push(tempArr.join(';'));
        blobArray.push('\n');
    }

    blobArray.push('\n');
    return blobArray;
}

export function saveArrayToCsv<T extends object>(fileName: string, registros: T[]): void {
    const rows = arrayToCsv(registros);
    downloadTextFile(rows, `${fileName}.csv`);
}

export function arrayToCsv<T extends object>(registros: T[]): string[] {
    const rows: string[] = [];
    const first = registros[0];

    if (registros.length === 0 || !first) {
        return rows;
    }

    const keys = Object.keys(first);
    rows.push(keys.join(';'));

    for (const registro of registros) {
        const columns: string[] = [];
        let extraRows: string[] = [];

        for (const key of keys) {
            const value = registro[key];

            if (typeof value === 'string') {
                columns.push(value);
                continue;
            }

            if (typeof value === 'number') {
                columns.push(value.toString().replace('.', ','));
                continue;
            }

            if (Array.isArray(value)) {
                extraRows = arrayToCsvArray(value);
            }
        }

        rows.push(columns.join(';'));

        for (const extraRow of extraRows) {
            rows.push(`;${extraRow}`);
        }
    }

    return rows;
}

export function arrayToCsvArray<T extends object>(array: T[]): string[] {
    const rows: string[] = [];
    const first = array[0];

    if (!first) {
        return [];
    }

    const keys = Object.keys(first);
    rows.push(keys.join(';'));

    for (const obj of array) {
        const columns: string[] = [];

        for (const key of keys) {
            const value = obj[key];

            if (typeof value === 'string') {
                columns.push(value);
                continue;
            }

            if (typeof value === 'number') {
                columns.push(value.toString().replace('.', ','));
            }
        }

        rows.push(columns.join(';'));
    }

    return rows;
}
