import { useLoginStore } from 'features/login/store';
import type { RootDispatch, RootState } from 'state/store';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import type { ApiResponse } from 'std/api/types';
import { throwIfResponseIsErr } from 'std/api/util';
import { buildUrl } from 'std/api2/buildUrl';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { Endpoint } from 'std/types/enum/endpoint';
import type { TComissaoMovimento, TComissaoMovimentoPostPutBody } from './types';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            idpk: number;
            parameter: 'nota_fiscal_idpk' | 'venda_idpk';
        },
        state: RootState,
    ): Promise<void> {
        const { parameter, idpk } = payload;

        dispatch.movimentoComissao.setState({
            get: startReduxFn(state.movimentoComissao.get.data),
        });

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            [parameter]: idpk,
        };

        const url = buildUrl(Endpoint.ComissaoMovimentoConsultar, params);

        try {
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros },
            } = response;

            dispatch.movimentoComissao.setState({
                get: endReduxFnOk(registros as TComissaoMovimento[]),
            });
        } catch (error) {
            dispatch.movimentoComissao.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async post(
        payload: {
            body: TComissaoMovimentoPostPutBody;
        },
        state: RootState,
    ): Promise<{ com_idpk: number } | string> {
        const { body } = payload;

        dispatch.movimentoComissao.setState({
            post: startReduxFn(state.movimentoComissao.post.data),
        });

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
        };

        const url = buildUrl(Endpoint.ComissaoMovimentoInserir, params);

        try {
            const response: ApiResponse = await comTokenPost(url, body);
            throwIfResponseIsErr(response);

            const {
                data: { registros },
            } = response;

            if (!registros?.[0]) {
                throw new Error('bad response');
            }

            dispatch.movimentoComissao.setState({
                post: endReduxFnOk({ com_idpk: registros[0].com_idpk as number }),
            });

            return { com_idpk: 0 };
        } catch (error) {
            dispatch.movimentoComissao.setState({
                post: endReduxFnError(error),
            });
            return JSON.stringify(error);
        }
    },

    async put(
        payload: {
            com_idpk: number;
            body: Partial<TComissaoMovimentoPostPutBody>;
        },
        state: RootState,
    ): Promise<string> {
        const { com_idpk, body } = payload;

        dispatch.movimentoComissao.setState({
            put: startReduxFn(state.movimentoComissao.put.data),
        });

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
        };

        const url = buildUrl(Endpoint.ComissaoMovimentoAlterar, params, com_idpk);

        try {
            const response: ApiResponse = await comTokenPut(url, body);
            throwIfResponseIsErr(response);

            dispatch.movimentoComissao.setState({
                put: endReduxFnOk('sucesso'),
            });

            return 'sucesso';
        } catch (error) {
            dispatch.movimentoComissao.setState({
                put: endReduxFnError(error),
            });
            return JSON.stringify(error);
        }
    },

    async remove(
        payload: {
            com_idpk: number;
        },
        state: RootState,
    ): Promise<string> {
        const { com_idpk } = payload;

        dispatch.movimentoComissao.setState({
            remove: startReduxFn(state.movimentoComissao.remove.data),
        });

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
        };

        const url = buildUrl(Endpoint.ComissaoMovimentoRemover, params, com_idpk);

        try {
            const response: ApiResponse = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.movimentoComissao.setState({
                remove: endReduxFnOk('sucesso'),
            });

            return 'sucesso';
        } catch (error) {
            dispatch.movimentoComissao.setState({
                remove: endReduxFnError(error),
            });
            return JSON.stringify(error);
        }
    },
});
