import { isValidElement } from 'react';

type MatchProps = {
    children: React.ReactNode | React.ReactNode[];
    fallback?: React.ReactNode;
};

// https://www.solidjs.com/docs/latest/api#%3Cswitch%3E%2F%3Cmatch%3E
/** Renderização condicional estilo switch/case
 * Foi utilizado o nome "Match" por causa que o antd já possui um componente chamado "Switch"
 * Usar o componente "Case" para renderizar um componente caso determinada condição por cumprida
 */
/** @deprecated - Utilizar outra forma pois é lento dessa forma */
export function Match(props: MatchProps) {
    const { children, fallback } = props;

    let result: React.ReactNode | null = null;
    const child = Array.isArray(children) ? children : [children];

    for (const element of child) {
        if (result || !isValidElement(element)) {
            break;
        }

        const { when = false } = element.props as { when: boolean };

        if (when) {
            result = element;
        }
    }

    return result || fallback || null;
}

type CaseProps = {
    when: boolean | undefined | null;
    children?: React.JSX.Element | React.JSX.Element[] | string;
};

/** @deprecated */
export function Case(props: CaseProps) {
    const { when, children } = props;
    return when && children ? <>{children}</> : null;
}
