import { red } from '@ant-design/colors';
import { notification } from 'antd';
import type { CardProperties } from 'components/revisar/CardList';
import type { ApiFinanceiroMovimento } from 'features/financeiro-movimento/types/ApiFinanceiroMovimento';
import { useLoginStore } from 'features/login/store';
import type { RootDispatch, RootState } from 'state/store';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import type { ApiResponse } from 'std/api/types';
import { isResponseErr, throwIfResponseIsErr } from 'std/api/util';
import { type BuildUrlParams, buildUrl } from 'std/api2/buildUrl';
import { numberToDecimal } from 'std/format';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import type { SimNao, ValorTotalItem } from 'std/types';
import { DefaultColors, TipoConsulta } from 'std/types/enum';
import { Endpoint } from 'std/types/enum/endpoint';
import { GetTypes } from 'std/types/interfaces/GetTypes';
import type {
    ContasReceberFile,
    ContasReceberPostBody,
    ContasReceberPostLancamentoBody,
    ContasReceberPutBody,
} from './types';

const DATE_FORMAT = 'DD-MM-YYYY';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            type: GetTypes;
            customUrl?: string;
            fmo_idpk?: number;
            tipo_consulta?: TipoConsulta;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.contasReceber.setState({
            get: startReduxFn(state.contasReceber.get.data),
        });

        try {
            const { type, customUrl, fmo_idpk, tipo_consulta = TipoConsulta.Resumida } = payload;
            const { contasReceber } = state;

            const {
                filterStatus,
                filterSituacao,
                filterPesquisar,
                filterCliente,
                filterConta,
                filterValor,
                filterCategoria,
                filterDataEmissao,
                filterDataVencimento,
                filterDataQuitacao,
                filterDataCancelamento,
                filterDataRecebimento,
                filterMarcador,
                filterExcluidos,
                filterContrato,
                selectAll,
                pagination,
                sortParams,
            } = contasReceber;

            // paginação precisa ser zerada antes de criar params
            if (
                type === GetTypes.recordFiltering ||
                type === GetTypes.addRecord ||
                type === GetTypes.singleRecord ||
                type === GetTypes.totalizadores
            ) {
                dispatch.contasReceber.setState({
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: 0,
                    },
                });
            }

            let params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                total_registros: type === GetTypes.paginationChange ? 'N' : 'S',
                registro_inicial: (pagination.current - 1) * (pagination.pageSize || 0),
                qtde_registros: pagination?.pageSize || 10,
                marcadores: filterMarcador,
                pesquisar: filterPesquisar,
                financeiro_categoria_idpk: filterCategoria?.fca_idpk,
                financeiro_conta_idpk: filterConta?.fco_idpk,
                contrato_idpk: filterContrato?.con_idpk,
                ...(sortParams.shouldSort
                    ? { orderby: `${sortParams.fieldName} ${sortParams.orderDirection}` }
                    : { orderby: 'fmo_data_vencimento desc' }),
                tipo_consulta,
            };

            let status: string[] | undefined;

            if (!filterStatus || filterStatus[0] === 'Total' || filterStatus.length === 0) {
                status = undefined;
            } else {
                status = filterStatus;
            }

            let where = '';

            if (filterCliente) {
                if (filterCliente.fmo_cliente_idpk) {
                    where = `fmo_cliente_idpk=${filterCliente.fmo_cliente_idpk}`;
                } else if (filterCliente.fmo_sacado_nome) {
                    where = `fmo_sacado_nome='${filterCliente.fmo_sacado_nome}'`;
                }
            }

            params = {
                ...params,
                ...(where.length > 0 && { where }),
                status,
            };

            if (filterDataEmissao) {
                params.data_emissao_de = filterDataEmissao.start.format(DATE_FORMAT);
                params.data_emissao_ate = filterDataEmissao.end.format(DATE_FORMAT);
            }

            if (filterDataVencimento) {
                params.data_vencimento_de = filterDataVencimento.start.format(DATE_FORMAT);
                params.data_vencimento_ate = filterDataVencimento.end.format(DATE_FORMAT);
            }

            if (filterDataQuitacao) {
                params.data_quitacao_de = filterDataQuitacao.start.format(DATE_FORMAT);
                params.data_quitacao_ate = filterDataQuitacao.end.format(DATE_FORMAT);
            }

            if (filterDataCancelamento) {
                params.data_cancelamento_de = filterDataCancelamento.start.format(DATE_FORMAT);
                params.data_cancelamento_ate = filterDataCancelamento.end.format(DATE_FORMAT);
            }

            if (filterDataRecebimento) {
                params.data_recebimento_de = filterDataRecebimento.start.format(DATE_FORMAT);
                params.data_recebimento_ate = filterDataRecebimento.end.format(DATE_FORMAT);
            }

            if (filterValor) {
                params.valor_de = numberToDecimal(filterValor.start);
                params.valor_ate = numberToDecimal(filterValor.end);
            }

            if (filterSituacao && filterSituacao !== 0) {
                params.situacao = filterSituacao;
            }

            if (filterExcluidos) {
                params.excluidos = 'S';
            }

            if (selectAll) {
                params.qtde_registros = undefined;
            }

            let url = '';

            if (type === GetTypes.customUrl && customUrl) {
                url = customUrl;
            } else if (type === GetTypes.singleRecord && fmo_idpk) {
                url = buildUrl(Endpoint.FinanceiroMovimentoContaReceber, params, fmo_idpk);
            } else {
                url = buildUrl(Endpoint.FinanceiroMovimentoContaReceber, params);
            }

            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            // paginação e filtros devem ser zerados após um get que alteraria a qtde de paginas
            if (
                type === GetTypes.firstGet ||
                type === GetTypes.addRecord ||
                type === GetTypes.totalizadores
            ) {
                dispatch.contasReceber.setState({
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: response.data.total_registros
                            ? Number(response.data.total_registros)
                            : 0,
                        showTotal: () => `Total de Registros: ${response.data.total_registros}`,
                        showSizeChanger: Number(response.data.total_registros) > 10,
                    },
                });
            }

            // a paginação que já existe no state deve ser mantida, mas o total/showTotal deve mudar
            if (type === GetTypes.deleteRecord || type === GetTypes.recordFiltering) {
                dispatch.contasReceber.setState({
                    pagination: {
                        ...pagination,
                        total: response.data.total_registros
                            ? Number(response.data.total_registros)
                            : 0,
                        showTotal: () => `Total de Registros: ${response.data.total_registros}`,
                        showSizeChanger: Number(response.data.total_registros) > 10,
                    },
                });
            }

            dispatch.contasReceber.setState({
                get: endReduxFnOk(response.data.registros || []),
            });

            // todo get, exceto por paginacao, totalizadores e first-get com totalizador já cheio
            // (no caso de uma troca entre vendas-orçamentos-pedidos), deve gerar um novo totalizador
            if (
                [
                    GetTypes.addRecord,
                    GetTypes.deleteRecord,
                    GetTypes.editRecord,
                    GetTypes.recordFiltering,
                ].includes(type) ||
                (type === GetTypes.firstGet &&
                    contasReceber.getTotais.data &&
                    contasReceber.getTotais.data.length === 0)
            ) {
                dispatch.contasReceber.getTotais(undefined);
            }
        } catch (error) {
            dispatch.contasReceber.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async getOne(payload: { fmo_idpk: number }, state: RootState): Promise<void> {
        dispatch.contasReceber.setState({
            getOne: startReduxFn(state.contasReceber.getOne.data),
        });

        const { fmo_idpk } = payload;

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            tipo_consulta: TipoConsulta.Detalhada,
        };

        const url = buildUrl(Endpoint.FinanceiroMovimentoContaReceber, params, fmo_idpk);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            dispatch.contasReceber.setState({
                getOne: endReduxFnOk(registros && registros.length > 0 ? registros[0] : null),
            });
        } catch (error) {
            dispatch.contasReceber.setState({
                getOne: endReduxFnError(error),
            });
        }
    },

    async post(
        payload: {
            agrupar: SimNao;
            body: ContasReceberPostBody;
            files: ContasReceberFile[];
        },
        state: RootState,
    ): Promise<void> {
        dispatch.contasReceber.setState({
            post: startReduxFn(state.contasReceber.post.data),
        });

        try {
            const { body, agrupar, files } = payload;

            const url = buildUrl(Endpoint.FinanceiroMovimentoContaReceber, {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                agrupar,
            });

            const response: ApiResponse = await comTokenPost(url, body);
            throwIfResponseIsErr(response);

            const fmo_idpk: number = response.data.registros?.[0]
                ? response.data.registros[0].fmo_idpk
                : 0;

            dispatch.contasReceber.postAnexo({
                fmo_idpk,
                files,
            });

            const oneBody = body.registros[0];

            dispatch.contasReceber.setState({
                post: endReduxFnOk(fmo_idpk),
                showModal: false,
                filterCliente: oneBody
                    ? {
                          fmo_sacado_nome: oneBody.fmo_sacado_nome || '',
                          fmo_cliente_idpk: oneBody.fmo_cliente_idpk || 0,
                          fmo_fornecedor_idpk: undefined,
                          fmo_sacado_cnpj_cpf: oneBody.fmo_sacado_cnpj_cpf || '',
                          fmo_sacado_doc_estrangeiro: oneBody.fmo_sacado_doc_estrangeiro || '',
                      }
                    : undefined,
            });

            dispatch.contasReceber.get({
                type: GetTypes.addRecord,
            });

            notification.success({
                message: 'Feito!',
                description: 'Conta cadastrada com sucesso',
            });
        } catch (error) {
            dispatch.contasReceber.setState({
                post: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: error.message,
            });
        }
    },

    async put(
        payload: {
            fmo_idpk: number;
            body: ContasReceberPutBody;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.contasReceber.setState({
            put: startReduxFn(state.contasReceber.put.data),
        });

        try {
            const { fmo_idpk, body } = payload;

            const params = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                grupo_parcelas_alterar: 'U',
            };

            const url = buildUrl(Endpoint.FinanceiroMovimentoContaReceber, params, fmo_idpk);
            const response: ApiResponse = await comTokenPut(url, body);
            throwIfResponseIsErr(response);

            dispatch.contasReceber.get({
                type: GetTypes.editRecord,
            });

            dispatch.contasReceber.setState({
                put: endReduxFnOk('success'),
                showModal: false,
            });

            notification.success({
                message: 'Feito!',
                description: 'Conta atualizada com sucesso',
            });
        } catch (error) {
            dispatch.contasReceber.setState({
                put: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: 'Falha ao atualizar conta',
            });
        }
    },

    async estornar(payload: {
        fmo_idpk: number;
        data_estorno?: string;
    }): Promise<void> {
        dispatch.contasReceber.setState({
            estornar: startReduxFn(),
        });

        try {
            const { fmo_idpk, data_estorno } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk, data_estorno };
            const url = buildUrl(Endpoint.FinanceiroMovimentoEstornar, params, fmo_idpk);
            const response: ApiResponse = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.contasReceber.get({
                type: GetTypes.editRecord,
            });

            dispatch.contasReceber.setState({
                estornar: endReduxFnOk(null),
            });
        } catch (error) {
            dispatch.contasReceber.setState({
                estornar: endReduxFnError(error),
            });

            notification.error({
                message: 'Falha ao estornar conta',
                description: error.message,
            });
        }
    },

    async getTotais(_: undefined, state: RootState): Promise<void> {
        dispatch.contasReceber.setState({
            getTotais: startReduxFn(state.contasReceber.getTotais.data),
        });

        try {
            const { contasReceber } = state;

            const {
                filterStatus,
                filterSituacao,
                filterCliente,
                filterPesquisar,
                filterValor,
                filterCategoria,
                filterDataEmissao,
                filterDataVencimento,
                filterDataQuitacao,
                filterDataCancelamento,
                filterDataRecebimento,
                filterMarcador,
                filterExcluidos,
                filterContrato,
                selectAll,
                pagination,
            } = contasReceber;

            let status: string[] | undefined;

            if (!filterStatus || filterStatus[0] === 'Total' || filterStatus.length === 0) {
                status = undefined;
            } else {
                status = filterStatus;
            }

            let where = '';

            if (filterCliente) {
                if (filterCliente.fmo_cliente_idpk) {
                    where = `fmo_cliente_idpk=${filterCliente.fmo_cliente_idpk}`;
                } else if (filterCliente.fmo_sacado_nome) {
                    where = `fmo_sacado_nome='${filterCliente.fmo_sacado_nome}'`;
                }
            }

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                registro_inicial: (pagination.current - 1) * (pagination.pageSize || 0),
                qtde_registros: selectAll ? undefined : pagination.pageSize || 10,
                ...(where.length > 0 && { where }),
                status,
                financeiro_categoria_idpk: filterCategoria ? filterCategoria.fca_idpk : undefined,
                pesquisar: filterPesquisar,
                orderby: 'fmo_data_vencimento',
                tipo: 'R',
                marcadores: filterMarcador,
                contrato_idpk: filterContrato?.con_idpk,
            };

            if (filterDataEmissao) {
                params.data_emissao_de = filterDataEmissao.start.format(DATE_FORMAT);
                params.data_emissao_ate = filterDataEmissao.end.format(DATE_FORMAT);
            }

            if (filterDataVencimento) {
                params.data_vencimento_de = filterDataVencimento.start.format(DATE_FORMAT);
                params.data_vencimento_ate = filterDataVencimento.end.format(DATE_FORMAT);
            }

            if (filterDataQuitacao) {
                params.data_quitacao_de = filterDataQuitacao.start.format(DATE_FORMAT);
                params.data_quitacao_ate = filterDataQuitacao.end.format(DATE_FORMAT);
            }

            if (filterDataCancelamento) {
                params.data_cancelamento_de = filterDataCancelamento.start.format(DATE_FORMAT);
                params.data_cancelamento_ate = filterDataCancelamento.end.format(DATE_FORMAT);
            }

            if (filterDataRecebimento) {
                params.data_recebimento_de = filterDataRecebimento.start.format(DATE_FORMAT);
                params.data_recebimento_ate = filterDataRecebimento.end.format(DATE_FORMAT);
            }

            if (filterValor) {
                params.valor_de = numberToDecimal(filterValor.start);
                params.valor_ate = numberToDecimal(filterValor.end);
            }

            if (filterSituacao && filterSituacao !== 0) {
                params.situacao = filterSituacao;
            }

            if (filterExcluidos) {
                params.excluidos = 'S';
            }

            const url = buildUrl(Endpoint.FinanceiroMovimentoValoresTotais, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const cores = {
                Vencidos: DefaultColors.Red,
                'Vencem hoje': red[7],
                'A vencer': DefaultColors.Orange,
                Recebidos: DefaultColors.Green,
                'Total do período': DefaultColors.Blue,
            };

            const totais: CardProperties[] = (response.data.registros || []).map(
                (total: ValorTotalItem) => ({
                    color: cores[total.status],
                    title: `${total.status} R$`,
                    amount: total.valor_total,
                    number: total.quantidade_total,
                    value: total.situacao?.toString() ?? '0',
                }),
            );

            dispatch.contasReceber.setState({
                getTotais: endReduxFnOk(totais),
            });
        } catch (error) {
            dispatch.contasReceber.setState({
                getTotais: endReduxFnError(error),
            });
        }
    },

    async postLancamento(
        payload: {
            fmo_idpk: number;
            body: ContasReceberPostLancamentoBody;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.contasReceber.setState({
            postLancamento: startReduxFn(state.contasReceber.postLancamento.data),
        });

        try {
            const { fmo_idpk, body } = payload;

            const params = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                financeiro_movimento_idpk: fmo_idpk,
            };

            const url = buildUrl(Endpoint.FinanceiroMovimentoLancamento, params);
            const response: ApiResponse = await comTokenPost(url, body);
            throwIfResponseIsErr(response);

            dispatch.contasReceber.get({
                type: GetTypes.editRecord,
            });

            dispatch.contasReceber.setState({
                postLancamento: endReduxFnOk('success'),
            });
        } catch (error) {
            dispatch.contasReceber.setState({
                postLancamento: endReduxFnError(error),
            });
        }
    },

    async estornarLancamento(payload: {
        fmo_idpk?: number;
        fml_idpk: number;
        fml_agrupado_id?: string;
        data_estorno?: string;
    }): Promise<void> {
        dispatch.contasReceber.setState({
            estornarLancamento: startReduxFn(),
        });

        try {
            const { fmo_idpk, fml_idpk, fml_agrupado_id, data_estorno } = payload;

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                data_estorno,
            };

            let url = '';

            if (fml_agrupado_id) {
                params.grupo_id = fml_agrupado_id;
                url = buildUrl(Endpoint.FinanceiroMovimentoLancamentoEstornar, params);
            } else {
                url = buildUrl(Endpoint.FinanceiroMovimentoLancamentoEstornar, params, fml_idpk);
            }

            const response: ApiResponse = await comTokenPut(url);
            throwIfResponseIsErr(response);

            if (fmo_idpk) {
                dispatch.contasReceber.get({ type: GetTypes.firstGet });
            }

            dispatch.contasReceber.setState({
                estornarLancamento: endReduxFnOk(null),
            });
        } catch (error) {
            dispatch.contasReceber.setState({
                estornarLancamento: endReduxFnError(error),
            });
        }
    },

    async postAnexo(
        payload: {
            fmo_idpk: number;
            files: ContasReceberFile[];
        },
        state: RootState,
    ): Promise<void> {
        const { fmo_idpk, files } = payload;

        // Caso não tiver arquivos simplesmente retorna
        if (files.length === 0) {
            dispatch.contasReceber.setState({
                postAnexo: endReduxFnOk(null),
            });

            return;
        }

        dispatch.contasReceber.setState({
            postAnexo: startReduxFn(state.contasReceber.postAnexo.data),
        });

        try {
            const params = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                financeiro_movimento_idpk: fmo_idpk,
            };

            const url = buildUrl(Endpoint.FinanceiroMovimentoAnexo, params);
            const body = { registros: files };
            const response: ApiResponse = await comTokenPost(url, body);
            throwIfResponseIsErr(response);

            dispatch.contasReceber.setState({
                postAnexo: endReduxFnOk(null),
            });

            notification.success({
                message: 'Sucesso!',
                description: 'Anexos foram cadastrados',
            });
        } catch (error) {
            dispatch.contasReceber.setState({
                postAnexo: endReduxFnError(error),
            });

            notification.success({
                message: 'Falha ao cadastrar anexos',
                description: error.message,
            });
        }
    },

    async removeAnexo(
        payload: {
            fmo_idpk: number;
            anexo_idpk: number;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.contasReceber.setState({
            removeAnexo: startReduxFn(state.contasReceber.removeAnexo.data),
        });

        try {
            const { fmo_idpk, anexo_idpk } = payload;

            const params = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                financeiro_movimento_idpk: fmo_idpk,
            };

            const url = buildUrl(Endpoint.FinanceiroMovimentoAnexo, params, anexo_idpk);
            const response: ApiResponse = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.contasReceber.setState({
                removeAnexo: endReduxFnOk(null),
            });
        } catch (error) {
            dispatch.contasReceber.setState({
                removeAnexo: endReduxFnError(error),
            });
        }
    },

    async gerarBoleto(
        payload: {
            row: ApiFinanceiroMovimento;
            descricao: string;
            conta: number;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.contasReceber.setState({
            gerarBoleto: startReduxFn(state.contasReceber.gerarBoleto.data),
        });

        try {
            const { row, descricao, conta } = payload;

            const body = {
                fmb_financeiro_conta_idpk: conta,
                fmb_valor: row.fmo_valor,
                fmb_vencimento: row.fmo_data_vencimento,
                fmb_instrucao: descricao,
            };

            const params = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                financeiro_movimento_idpk: row.fmo_idpk,
            };

            const url = buildUrl(Endpoint.FinanceiroMovimentoBoleto, params);
            const response: ApiResponse = await comTokenPost(url, body);

            if (isResponseErr(response)) {
                throw new Error(response.data.personalizado?.list[0]?.message || '');
            }

            dispatch.contasReceber.setState({
                gerarBoleto: endReduxFnOk(null),
            });

            notification.success({
                message: 'Sucesso!',
                description: 'Boleto gerado com sucesso',
            });
        } catch (error) {
            dispatch.contasReceber.setState({
                gerarBoleto: endReduxFnError(error),
            });

            notification.error({
                message: 'Falha ao gerar boleto',
                description: error.message,
            });
        }
    },

    async gerarPixCobranca(
        payload: {
            row: ApiFinanceiroMovimento;
            descricao: string;
            conta: number;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.contasReceber.setState({
            gerarPixCobranca: startReduxFn(state.contasReceber.gerarPixCobranca.data),
        });

        try {
            const { row, descricao, conta } = payload;

            const body = {
                fmp_cliente_idpk: row.fmo_cliente_idpk,
                fmp_descricao: descricao,
                fmp_valor: row.fmo_valor,
                fmp_financeiro_conta_idpk: conta,
                fmp_vencimento: row.fmo_data_vencimento,
            };

            const params = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                financeiro_movimento_idpk: row.fmo_idpk,
            };

            const url = buildUrl(Endpoint.FinanceiroMovimentoPixCobranca, params);
            const response: ApiResponse = await comTokenPost(url, body);

            if (isResponseErr(response)) {
                throw new Error(response.data.personalizado?.list[0]?.message || '');
            }

            dispatch.contasReceber.setState({
                gerarPixCobranca: endReduxFnOk(null),
            });

            notification.success({
                message: 'Sucesso!',
                description: 'Pix cobrança gerado com sucesso',
            });
        } catch (error) {
            dispatch.contasReceber.setState({
                gerarPixCobranca: endReduxFnError(error),
            });

            notification.error({
                message: 'Falha ao gerar pix cobrança',
                description: error.message,
            });
        }
    },

    async excluirBoleto(payload: { idpk: number }, state: RootState): Promise<void> {
        dispatch.contasReceber.setState({
            excluirBoleto: startReduxFn(state.contasReceber.excluirBoleto.data),
        });

        try {
            const { idpk } = payload;

            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.FinanceiroMovimentoBoleto, params, idpk);
            const response: ApiResponse = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.contasReceber.setState({
                excluirBoleto: endReduxFnOk(null),
            });

            notification.success({
                message: 'Sucesso!',
                description: 'Boleto foi excluído',
            });
        } catch (error) {
            dispatch.contasReceber.setState({
                excluirBoleto: endReduxFnError(error),
            });

            notification.error({
                message: 'Falha ao excluir boleto',
                description: 'error.message',
            });
        }
    },

    async excluirPixCobranca(payload: { idpk: number }, state: RootState): Promise<void> {
        dispatch.contasReceber.setState({
            excluirPixCobranca: startReduxFn(state.contasReceber.excluirPixCobranca.data),
        });

        try {
            const { idpk } = payload;

            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.FinanceiroMovimentoPix, params, idpk);
            const response: ApiResponse = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.contasReceber.setState({
                excluirPixCobranca: endReduxFnOk(null),
            });

            notification.success({
                message: 'Sucesso!',
                description: 'Pix cobrança foi excluído',
            });
        } catch (error) {
            dispatch.contasReceber.setState({
                excluirPixCobranca: endReduxFnOk(null),
            });

            notification.error({
                message: 'Falha ao excluir pix cobrança',
                description: error.message,
            });
        }
    },
});
