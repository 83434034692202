import type { ShoppingCartProduto, TCatalogoClienteData } from 'models/catalogo-produtos/types';
import { persist } from 'zustand/middleware';
import { createWithEqualityFn as create } from 'zustand/traditional';

type CatalogoStore = {
    dadosFinalizarPedido: TCatalogoClienteData | undefined;
    produtosCarrinho: ShoppingCartProduto[];
};

export const useCatalogoStore = create<CatalogoStore>()(
    persist(
        (_) => ({
            dadosFinalizarPedido: undefined,
            produtosCarrinho: [],
        }),
        {
            name: 'catalogo',
        },
    ),
);
