import { createFileRoute } from '@tanstack/react-router';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { validateRoute } from 'router/util';

export const Route = createFileRoute('/a/notificacao')({
    beforeLoad: () =>
        validateRoute({
            nivel: TipoPermissao.Completo,
            podeExibirFintech: true,
        }),
});
