import { createFileRoute } from '@tanstack/react-router';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { validateRoute } from 'router/util';

export const Route = createFileRoute('/a/compra')({
    beforeLoad: () => {
        return validateRoute({
            permissao: 'upp_compra_compra',
            nivel: TipoPermissao.Completo,
            podeExibirFintech: false,
        });
    },
});
