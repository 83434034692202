import { useLoginStore } from 'features/login/store';
import { fetchApi } from 'std/api2/fetchApi';
import { ErroOrigem } from '../types';

type InternoErroBody =
    | {
          type: 'EXCEPTION';
          message: string;
          source: string | undefined;
          lineno: number | undefined;
          colno: number | undefined;
          stack: string[] | undefined;
      }
    | {
          type: 'ZOD_PARSE_ERROR';
          message: string;
      };

export function apiInternoErro(body: InternoErroBody): Promise<unknown> {
    return fetchApi({
        token: false,
        endpoint: 'Interno/Erro',
        method: 'post',
        params: {
            origem: ErroOrigem.Frontend,
        },
        body: {
            APP_VERSION,
            url: window.location.href,
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            ...body,
        },
    });
}
