import { createFileRoute } from '@tanstack/react-router';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { VENDA_TIPO, type VendaTipo } from 'features/venda/types/Venda';
import { validateRoute } from 'router/util';

export const Route = createFileRoute('/a/venda/$tipo')({
    beforeLoad: ({ params }) => {
        const { tipo } = params;

        validateRoute({
            permissao: 'upp_venda_venda',
            nivel: TipoPermissao.Parcial,
            podeExibirFintech: true,
            condicao: VENDA_TIPO.includes(tipo as VendaTipo),
        });
    },
});
