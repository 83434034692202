import { notification } from 'antd';
import type { CardProperties } from 'components/revisar/CardList';
import { useLoginStore } from 'features/login/store';
import type { RootDispatch, RootState } from 'state/store';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import { throwIfResponseIsErr } from 'std/api/util';
import { type BuildUrlParams, buildUrl } from 'std/api2/buildUrl';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { DefaultColors } from 'std/types/enum';
import { Endpoint } from 'std/types/enum/endpoint';
import type { SortParams } from 'std/types/interfaces';
import type { TComissao, TValorTotaisComissao } from './types';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            vendedorIdpk?: number;
            mesAno?: string;
            status?: string;
            tipo?: string;
            total_registros?: string;
            registro_inicial?: number;
            qtde_registros?: number;
            sort?: SortParams;
        },
        state: RootState,
    ): Promise<void> {
        const { comissoes } = state;
        const { getTable, get } = comissoes;

        dispatch.comissoes.setState({
            get: startReduxFn(get.data),
            getTable: {
                ...getTable,
                updateTable: false,
            },
        });

        try {
            const {
                filterMesAno,
                filterVendedor,
                filterStatus,
                filterTipo,
                filterWhere,
                filterDescricao,
                filterComissaoTipo,
                registroInitial,
                qtdRegistros,
                sortParams,
                pagination,
                resetPagination,
            } = getTable;

            const {
                vendedorIdpk,
                mesAno,
                status,
                tipo,
                total_registros,
                registro_inicial,
                qtde_registros,
                sort,
                ...restPayload
            } = payload;

            // MONTANDO OS PARAMETROS OBRIGATÓRIOS
            const params: BuildUrlParams = {
                // data_inicio: filterDateRange?.start.format('DD-MM-YYYY'),
                // data_fim: filterDateRange?.end.format('DD-MM-YYYY'),
                registro_inicial:
                    registro_inicial !== null && registro_inicial !== undefined
                        ? registro_inicial
                        : registroInitial,
                qtde_registros: qtde_registros || qtdRegistros,
                orderby: 'com_data desc, com_idpk desc',
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                ...restPayload,
            };

            if (vendedorIdpk || filterVendedor?.idpk) {
                params.comissionado_idpk = vendedorIdpk || filterVendedor?.idpk;
            }

            if (mesAno || filterMesAno) {
                params.mes_referencia = mesAno || filterMesAno;
            }

            // CASO TIVER O FILTRO DE TIPO (*OPCIONAL)
            if (!!status || !!filterStatus) {
                params.status = status || filterStatus;
            }

            // CASO TIVER O FILTRO DE TIPO (*OPCIONAL)
            if (!!tipo || !!filterTipo) {
                params.tipos = `[${tipo || filterTipo}]`;
            }

            // CASO TIVER O FILTRO DE DESCRICAO (*OPCIONAL)
            if (filterDescricao) {
                params.descricao = filterDescricao;
            }

            // CASO TIVER O FILTRO DE DESCRICAO (*OPCIONAL)
            if (filterComissaoTipo) {
                params.comissionado_tipo = filterComissaoTipo;
            }

            // CASO TIVER O FILTRO DE WHERE (*OPCIONAL)
            if (filterWhere) {
                params.where = filterWhere;
            }

            // CASO TENHA QUE ORDENAR OS DADOS (*OPCIONAL)
            if (sort?.shouldSort || sortParams?.shouldSort) {
                params.orderby = `${sort?.fieldName || sortParams?.fieldName}${
                    sort?.orderDirection || sortParams?.orderDirection
                }`;
            }
            // CASO TIVER ENVIADO PARA MOSTRAR TODOS REGISTROS
            if (total_registros) {
                params.total_registros = total_registros;
            }

            const url = buildUrl(Endpoint.ComissaoMovimentoConsultar, params);
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [], total_registros: totalRegistroResponse = null } = {},
            } = response;

            dispatch.comissoes.setState({
                get: endReduxFnOk(registros),
                getTable: {
                    ...getTable,
                    updateTable: false,
                    ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                        totalRegistrosTable: totalRegistroResponse,
                    }),
                    // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                    ...(resetPagination && {
                        registro_inicial: 0,
                    }),
                    pagination: {
                        ...pagination,
                        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                        ...(resetPagination && {
                            current: 1,
                        }),
                        // SE PRECISAR ATUALIZAR A PÁGINA É FEITO AQUI
                        ...(registro_inicial !== null &&
                            registro_inicial !== undefined && {
                                current: registro_inicial / (qtde_registros || qtdRegistros) + 1,
                            }),
                        // SE PRECISAR ATUALIZAR OS TOTAIS É FEITO AQUI
                        ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                            total: totalRegistroResponse,
                            showTotal: () => `Total de Registros: ${totalRegistroResponse}`,
                            showSizeChanger: totalRegistroResponse > 10,
                        }),
                    },
                },
            });
        } catch (error) {
            dispatch.comissoes.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async getTotais(_, state: RootState): Promise<void> {
        const { comissoes } = state;
        const { getTotais, getTable } = comissoes;

        dispatch.comissoes.setState({
            getTotais: startReduxFn(getTotais.data),
        });

        const { filterMesAno, filterVendedor, filterDescricao, filterComissaoTipo, pagination } =
            getTable;

        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            comissionado_idpk: filterVendedor?.idpk,
            mes_referencia: filterMesAno,
            descricao: filterDescricao,
            comissionado_tipo: filterComissaoTipo,
            total_registros: 'S',
            registro_inicial: 0,
            qtde_registros: pagination?.pageSize || 10,
        };

        const url = buildUrl(Endpoint.ComissaoMovimentoValoresTotais, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            const cores = {
                'Saldo inicial período': DefaultColors.Black,
                Entrada: DefaultColors.Green,
                Pendente: DefaultColors.Purple,
                Saída: DefaultColors.Red,
                'Saldo final período': DefaultColors.Black,
            };

            const totais: CardProperties[] = registros.map((total: TValorTotaisComissao) => ({
                color: cores[total.tipo],
                title: total.tipo,
                amount: total.valor_total,
                number: total.quantidade_total,
                value: total.tipo,
            }));

            dispatch.comissoes.setState({
                getTotais: endReduxFnOk(totais),
            });
        } catch (error) {
            dispatch.comissoes.setState({
                getTotais: endReduxFnError(error),
            });
        }
    },

    async getOne(payload: { com_idpk: number }, state: RootState): Promise<TComissao> {
        dispatch.comissoes.setState({
            getOne: startReduxFn(state.comissoes.getOne.data),
        });

        const { com_idpk } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.ComissaoMovimentoConsultar, params, com_idpk);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            const returnGetOne = registros && registros.length > 0 ? registros[0] : null;

            dispatch.comissoes.setState({
                getOne: endReduxFnOk(returnGetOne),
            });

            return returnGetOne;
        } catch (error) {
            dispatch.comissoes.setState({
                getOne: endReduxFnError(error),
            });
            return error;
        }
    },

    async post(
        payload: { body: TComissao; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        dispatch.comissoes.setState({
            post: startReduxFn(state.comissoes.post.data),
        });

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const { body, updateTable } = payload;
            const url = buildUrl(Endpoint.ComissaoMovimentoInserir, params);
            const response = await comTokenPost(url, body);
            throwIfResponseIsErr(response);

            const com_idpk: number = response.data.registros?.[0]
                ? response.data.registros[0].com_idpk
                : 0;

            dispatch.comissoes.setState({
                post: endReduxFnOk({ com_idpk }),
            });

            if (updateTable) {
                dispatch.comissoes.get({});
                dispatch.comissoes.getTotais({});
            }

            notification.success({
                message: 'Feito!',
                description: 'Comissão cadastrada',
            });
        } catch (error) {
            dispatch.comissoes.setState({
                post: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível cadastrar a Comissão!',
                description: error.message,
            });
        }
    },

    async put(
        payload: {
            com_idpk: number;
            body: TComissao;
            updateTable?: boolean;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.comissoes.setState({
            put: startReduxFn(state.comissoes.put.data),
        });

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const { com_idpk = 0, body, updateTable } = payload;
            const url = buildUrl(Endpoint.ComissaoMovimentoAlterar, params, com_idpk);
            const response = await comTokenPut(url, body);
            throwIfResponseIsErr(response);

            dispatch.comissoes.setState({
                put: endReduxFnOk('Success'),
            });

            if (updateTable) {
                dispatch.comissoes.get({});
                dispatch.comissoes.getTotais({});
            }

            notification.success({
                message: 'Feito!',
                description: 'Comissão atualizada',
            });
        } catch (error) {
            dispatch.comissoes.setState({
                put: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível atualizar a Comissão!',
                description: error.message,
            });
        }
    },

    async remove(
        payload: { com_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { comissoes } = state;
        const { getTable } = comissoes;

        dispatch.comissoes.setState({
            remove: startReduxFn(state.comissoes.remove.data),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        try {
            const { com_idpk = 0, updateTable } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.ComissaoMovimentoRemover, params, com_idpk);
            const response = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.comissoes.setState({
                remove: endReduxFnOk('Success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                const {
                    pagination,
                    pagination: { total = 0 },
                    registroInitial = 0,
                } = getTable;

                let isLastPageOnlyOneRegister = false;

                // VERIFICA SE É A ÚLTIMA PÁGINA E TEM APENAS UM ITEM PARA PODER MUDAR DE PÁGINA APÓS DELETAR
                if (total && registroInitial && total - 1 === registroInitial) {
                    isLastPageOnlyOneRegister = true;
                    dispatch.comissoes.get({
                        total_registros: 'S',
                        registro_inicial: registroInitial - (pagination?.pageSize || 0),
                    });

                    dispatch.comissoes.getTotais({});
                }

                if (!isLastPageOnlyOneRegister) {
                    dispatch.comissoes.get({
                        total_registros: 'S',
                    });

                    dispatch.comissoes.getTotais({});
                }
            }

            notification.success({
                message: 'Feito!',
                description: 'Comissão excluída.',
            });
        } catch (error) {
            dispatch.comissoes.setState({
                remove: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },
});
