import type { ApiNfceEmissao } from 'features/nfce/types/ApiNfceEmissao';
import { newRequest } from 'std/api/newRequest';
import type { ApiBaseParams } from 'std/api/types';
import type { SimNao } from 'std/types';
import type { TipoConsulta } from 'std/types/enum';
import type { Venda, VendaTipo } from './types/Venda';

export type ApiVendaGetParams = ApiBaseParams &
    Partial<{
        /** 
            Tipo de data que será considerada no período:
            - 1: Data de geração/inclusão
            - 2: Data do orçamento
            - 3: Data do pedido
            - 4: Data da venda
            - 5: Data da bonificação 
        */
        data_tipo: 1 | 2 | 3 | 4 | 5;
        data_inicio: string;
        data_fim: string;
        pesquisar: string;
        tipo: VendaTipo;
        numero: number;
        origem: 'INTERNO' | 'PDV' | 'APP';
        tipo_consulta: TipoConsulta;
        vendedor_idpk: number;
        cliente_cidade: string;
        cliente_idpk: number;
        marcadores: number[];
        acao: string;
    }>;

export function apiVendaGet({
    idpk,
    params,
}: { idpk?: number; params?: ApiVendaGetParams }): Promise<Venda[]> {
    return newRequest({
        url: 'Venda/Venda',
        type: 'get',
        idpk,
        params,
    });
}

export type ApiVendaPost = {
    ven_idpk: number;
    ven_numero: string;
    ven_vendedor_sequencial: number;
    ven_link_compartilhamento: string;
};

export function apiVendaPost(body: Partial<Venda>): Promise<ApiVendaPost> {
    return newRequest({
        url: 'Venda/Venda',
        type: 'post',
        body,
    });
}

export function apiVendaPut({ idpk, body }: { idpk: number; body: Venda }): Promise<unknown> {
    return newRequest({
        url: 'Venda/Venda',
        type: 'put',
        idpk,
        body,
    });
}

export function apiVendaDelete(idpk: number): Promise<unknown> {
    return newRequest({
        url: 'Venda/Venda',
        type: 'delete',
        idpk,
    });
}

export type ApiVendaGerarNfce = {
    venda_idpk: number;
    nfce_idpk: number;
    info?: string;
    emissao?: ApiNfceEmissao;
};

export function apiVendaGerarNfce({
    params,
}: {
    params: {
        venda_list: number[];
        emitir: SimNao;
    };
}): Promise<ApiVendaGerarNfce[]> {
    return newRequest({
        url: 'Venda/GerarNFCe',
        type: 'post',
        params,
    });
}

export function apiVendaTransferirTipo({
    idpk,
    params,
}: {
    idpk: number;
    params: {
        /**
            Tipos disponíveis:
            - V: Venda (Finalizada);
            - P: Pedido (Pendente);
            - O: Orçamento (Pendente);
        */
        novo_tipo: 'V' | 'P' | 'O';
    };
}): Promise<void> {
    return newRequest({
        url: 'Venda/TransferirTipo',
        type: 'put',
        idpk,
        params,
    });
}

export function apiVendaAnteciparVendaPrevista({ idpk }: { idpk: number }): Promise<void> {
    return newRequest({
        url: 'Venda/AnteciparVendaPrevista',
        type: 'put',
        idpk,
    });
}
