import { useQuery } from '@tanstack/react-query';
import { apiEmpresaGet } from 'features/empresa/api/apiEmpresaGet';
import type { Empresa } from 'features/empresa/types/Empresa';
import { useLoginStore } from 'features/login/store';
import { useEffect, useMemo } from 'react';

export function useEmpresaQuery(): {
    empresa: Empresa | undefined;
    isLoadingEmpresa: boolean;
} {
    const empresaIdpk = useLoginStore((s) => s.empresaIdpk);
    const updateEmpresa = useLoginStore((s) => s.updateEmpresa);

    const { data: empresas, isLoading } = useQuery({
        queryKey: ['apiEmpresaGet'],
        queryFn: () => apiEmpresaGet(),
        enabled: empresaIdpk !== undefined,
    });

    const empresa = useMemo(() => empresas?.[0], [empresas]);

    useEffect(() => {
        if (empresa) {
            updateEmpresa(empresa);
        }
    }, [updateEmpresa, empresa]);

    return {
        empresa,
        isLoadingEmpresa: isLoading,
    };
}
