import { createModel } from '@rematch/core';
import dayjs from 'dayjs';
import { initReduxFn, setStateReducer } from 'std/redux';
import type { RootModel } from '..';
import { effects } from './effects';
import type { VendasState } from './types';

const initialState: VendasState = {
    // EFFECTS
    get: initReduxFn,
    post: initReduxFn,
    put: initReduxFn,
    remove: initReduxFn,
    totalizador: initReduxFn,
    cancelarOrcamento: initReduxFn,
    gerarRelatorio: initReduxFn,
    gerarPDF: initReduxFn,
    anteciparVenda: initReduxFn,
    lancarEstoque: initReduxFn,
    estornarEstoque: initReduxFn,
    lancarFinanceiro: initReduxFn,
    estornarFinanceiro: initReduxFn,
    gerarNotaNFe: initReduxFn,
    gerarNotaNFCe: initReduxFn,
    getCompartilhamentoDeVenda: initReduxFn,
    enviarExpedicao: initReduxFn,

    // TABELAS
    getTable: {
        // FILTROS
        filterTipo: undefined,
        filterTipoIntervalo: '1',
        filterIntervalo: {
            start: dayjs().startOf('date').subtract(30, 'day'),
            end: dayjs().endOf('date'),
        },
        filterCliente: undefined,
        filterTransportadora: undefined,

        // TOTAIS REGISTROS (PARA PAGINAÇÃO)
        totalRegistrosTable: 0,
        registroInitial: 0,
        qtdRegistros: 10,

        // ORDENAÇÃO
        sortParams: {
            shouldSort: false,
            fieldName: '',
            orderDirection: 'asc',
        },

        // PAGINAÇÃO
        pagination: {
            current: 1,
            pageSize: 10,
            total: 10,
            showTotal: () => '',
            showSizeChanger: false,
        },

        // LINHA SELECIONADAS
        selectedRow: undefined,
        selectedRows: [],

        // UPDATE TABLE ON CHANGES
        updateTable: false,

        // LOADING NA TABELA PARA MOSTRAR ALGUMA AÇÃO EXECUTANDO
        loadingTable: false,
    },

    // MOSTRAR DRAWERS
    showDrawerGetDetalhes: false,
    showDrawerCompartilhar: false,
    showDrawerProdutos: false,

    // VALORES PARA DRAWERS
    drawerProdutoItem: undefined,
    drawerProdutoIndex: undefined,
    dadosCompartilhar: {
        link: '',
        assunto: '',
        mensagem: '',
        idpk: 0,
    },
};

export const VendasModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<VendasState>(),
    effects,
});
