import { createModel } from '@rematch/core';
import dayjs from 'dayjs';
import { initReduxFn, setStateReducer } from 'std/redux';
import type { RootModel } from '..';
import { effects } from './effects';
import type { NotaFiscalState } from './types';

const initialState: NotaFiscalState = {
    // EFFECTS
    get: initReduxFn,
    getOne: initReduxFn,
    post: initReduxFn,
    put: initReduxFn,
    remove: initReduxFn,
    totalizador: initReduxFn,
    cancelarNotaFiscal: initReduxFn,
    preVisualizarDanfe: initReduxFn,
    gerarRelatorio: initReduxFn,
    gerarRelatorioMultiple: initReduxFn,
    emitirNotaFiscal: { ...initReduxFn, cancel: false },
    compartilharSend: initReduxFn,
    gerarCartaCorrecao: initReduxFn,
    sendCartaCorrecao: initReduxFn,
    calcularImpostos: initReduxFn,
    getReceitaFiscal: initReduxFn,
    lancarEstoque: initReduxFn,
    estornarEstoque: initReduxFn,
    lancarFinanceiro: initReduxFn,
    estornarFinanceiro: initReduxFn,
    getCompartilhamentoDeNotaFiscal: initReduxFn,
    gerarEstorno: initReduxFn,
    enviarExpedicao: initReduxFn,
    verifyAndGerarRelatorioPdf: initReduxFn,

    // TABELAS
    getTable: {
        // FILTROS
        filterDateRange: {
            start: dayjs().startOf('date').subtract(30, 'day'),
            end: dayjs().endOf('date'),
        },

        // TOTAIS REGISTROS (PARA PAGINAÇÃO)
        totalRegistrosTable: 0,
        registroInitial: 0,
        qtdRegistros: 10,

        // ORDENAÇÃO
        sortParams: {
            shouldSort: false,
            fieldName: '',
            orderDirection: 'asc',
        },

        // PAGINAÇÃO
        pagination: {
            current: 1,
            pageSize: 10,
            total: 10,
            showTotal: () => '',
            showSizeChanger: false,
        },

        // LINHA SELECIONADAS
        selectedRow: undefined,
        selectedRows: [],

        // UPDATE TABLE ON CHANGES
        updateTable: false,

        // LOADING NA TABELA PARA MOSTRAR ALGUMA AÇÃO EXECUTANDO
        loadingTable: false,
    },

    // MOSTRAR DRAWERS
    showDrawerGetDetalhes: false,
    showDrawerCompartilhar: false,
    showDrawerEmitirNf: false,
    showDrawerCancelarNf: false,
    showDrawerEstornoNf: false,
    showDrawerProdutos: false,
    showDrawerDeclaracao: false,

    // VALORES PARA DRAWERS
    drawerProdutoItem: undefined,
    drawerProdutoIndex: undefined,
    dadosCompartilhar: {
        link: '',
        assunto: '',
        mensagem: '',
        idpk: 0,
    },

    // FORM
    formEditMode: undefined,
    formError: undefined,

    // Aba Tabs
    abaTabs: '',
};

export const NotaFiscalModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<NotaFiscalState>(),
    effects,
});
