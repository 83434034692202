import { z } from 'zod';

export const UsuarioSchema = z.object({
    usu_idpk: z.number(),
    usu_sistema_idpk: z.number(),
    usu_nome: z.string(),
    /** 
        Campo utilizado para informar se a senha será renova e então validar o link com a data de expiração;
        0 = NÃO
        1 = SIM 
    */
    usu_renovar_senha: z.union([z.literal(0), z.literal(1)]),
    usu_representante_idpk: z.number().nullable(),
    usu_email: z.string().nullable(),
    usu_fone: z.string().nullable(),
    usu_imagem_perfil: z.string().nullable(),
    usu_observacao: z.string().nullable(),
    usu_renovar_senha_expiracao: z.string().nullable(),
    usu_renovar_senha_token: z.string().nullable(),
});

export type Usuario = z.infer<typeof UsuarioSchema>;
