export enum EmpresaSistemaTipoUso {
    Completo = 'C',
    Financeiro = 'F',
}

export enum EmpresaSituacao {
    Aprovada = 1,
    Pendente = 2,
    Bloqueada = 3,
}

export enum EmpresaTipoEmissao {
    Normal = 1,
    Contingencia = 2,
}

export enum EmpresaMicro {
    Padrao = 0,
    Sim = 1,
    Nao = 2,
}

export enum EmpresaIndicadorIE {
    Contribuinte = 1,
    NaoContribuinte = 2,
    Isento = 3,
}

export enum EmpresaPermiteCredito {
    Sim = 1,
    Nao = 2,
}

export enum EmpresaCrtCrt {
    SimplesNacional = 1,
    SimplesNacionalExcesso = 2,
    RegimeNormal = 3,
    Mei = 4,
    Outro = 5,
}
