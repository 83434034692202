import { z } from 'zod';

export enum TipoPermissao {
    Bloqueado = 0,
    Completo = 1,
    Parcial = 2,
}

export type UsuarioPermissao = keyof Omit<
    UsuarioTipoPermissao,
    'upp_idpk' | 'upp_empresa_idpk' | 'upp_usuario_tipo_idpk'
>;

export const UsuarioTipoPermissaoSchema = z.object({
    upp_idpk: z.number(),
    upp_empresa_idpk: z.number(),
    upp_usuario_tipo_idpk: z.number(),
    upp_cadastro_cliente: z.nativeEnum(TipoPermissao),
    upp_cliente_forma_pagamento: z.nativeEnum(TipoPermissao),
    upp_cliente_condicao_pagamento: z.nativeEnum(TipoPermissao),
    upp_cadastro_produto: z.nativeEnum(TipoPermissao),
    upp_cadastro_fornecedor: z.nativeEnum(TipoPermissao),
    upp_cadastro_transportadora: z.nativeEnum(TipoPermissao),
    upp_estoque_estoque: z.nativeEnum(TipoPermissao),
    upp_venda_venda: z.nativeEnum(TipoPermissao),
    upp_venda_romaneio: z.nativeEnum(TipoPermissao),
    upp_venda_contrato: z.nativeEnum(TipoPermissao),
    upp_venda_venda_externa: z.nativeEnum(TipoPermissao),
    upp_venda_atendimento: z.nativeEnum(TipoPermissao),
    upp_venda_comissao: z.nativeEnum(TipoPermissao),
    upp_fiscal_nfe: z.nativeEnum(TipoPermissao),
    upp_fiscal_nfce: z.nativeEnum(TipoPermissao),
    upp_fiscal_mdfe: z.nativeEnum(TipoPermissao),
    upp_fiscal_regras: z.nativeEnum(TipoPermissao),
    upp_fiscal_buscador_notas: z.nativeEnum(TipoPermissao),
    upp_compra_compra: z.nativeEnum(TipoPermissao),
    upp_notificacao_notificacao: z.nativeEnum(TipoPermissao),
    upp_financeiro_movimentacao: z.nativeEnum(TipoPermissao),
    upp_financeiro_conta_receber: z.nativeEnum(TipoPermissao),
    upp_financeiro_conta_pagar: z.nativeEnum(TipoPermissao),
    upp_financeiro_boleto: z.nativeEnum(TipoPermissao),
    upp_financeiro_pix: z.nativeEnum(TipoPermissao),
    upp_financeiro_cartao: z.nativeEnum(TipoPermissao),
    upp_financeiro_estorno: z.nativeEnum(TipoPermissao),
    upp_fintech_administrativo: z.nativeEnum(TipoPermissao),
    upp_relatorio_venda: z.nativeEnum(TipoPermissao),
    upp_relatorio_fiscal: z.nativeEnum(TipoPermissao),
    upp_relatorio_financeiro: z.nativeEnum(TipoPermissao),
    upp_relatorio_estoque: z.nativeEnum(TipoPermissao),
    upp_relatorio_cadastro: z.nativeEnum(TipoPermissao),
    upp_relatorio_compra: z.nativeEnum(TipoPermissao),
    upp_configuracao_configuracao: z.nativeEnum(TipoPermissao),
    upp_sistema_personalizado: z.nativeEnum(TipoPermissao),
});

export type UsuarioTipoPermissao = z.infer<typeof UsuarioTipoPermissaoSchema>;
