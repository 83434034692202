import { queryClient } from 'App/queryClient';
import { notification } from 'antd';
import type dayjs from 'dayjs';
import { useLoginStore } from 'features/login/store';
import type { FormCompra } from 'pages/compras/forms/types';
import type { RootDispatch, RootState } from 'state/store';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import { throwIfResponseIsErr } from 'std/api/util';
import { buildUrl } from 'std/api2/buildUrl';
import { fileToBase64 } from 'std/base64';
import { openPDF } from 'std/pdf';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { Endpoint } from 'std/types/enum/endpoint';
import { downloadFile } from 'std/util';
import type { TReturnManifestadoCarregar } from './types';

export const effects = (dispatch: RootDispatch) => ({
    async post(payload: { body: FormCompra }, state: RootState): Promise<void> {
        dispatch.compras.setState({
            post: startReduxFn(state.compras.post.data),
        });

        const { body } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.Compra, params);

        try {
            const response = await comTokenPost(url, body);
            throwIfResponseIsErr(response);

            const com_idpk: number = response?.data?.registros
                ? response.data.registros[0].com_idpk
                : 0;

            dispatch.compras.setState({
                post: endReduxFnOk({ com_idpk }),
            });

            notification.success({
                message: 'Feito!',
                description: 'Compra cadastrada',
            });
        } catch (error) {
            dispatch.compras.setState({
                post: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível cadastrar a compra!',
                description: error.message,
            });
        }
    },

    async put(payload: { com_idpk: number; body: FormCompra }, state: RootState): Promise<void> {
        dispatch.compras.setState({
            put: startReduxFn(state.compras.put.data),
        });

        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
        const { com_idpk = 0, body } = payload;

        const url = buildUrl(Endpoint.Compra, params, com_idpk);

        try {
            const response = await comTokenPut(url, body);
            throwIfResponseIsErr(response);

            dispatch.compras.setState({
                put: endReduxFnOk('Success'),
            });

            notification.success({
                message: 'Feito!',
                description: 'Compra atualizada',
            });
        } catch (error) {
            dispatch.compras.setState({
                put: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível atualizar a compra!',
                description: error.message,
            });
        }
    },

    async remove(
        payload: { com_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        dispatch.compras.setState({
            remove: startReduxFn(state.compras.remove.data),
        });

        const { com_idpk = 0, updateTable } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.Compra, params, com_idpk);

        try {
            const response = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.compras.setState({
                remove: endReduxFnOk('Success'),
            });

            if (updateTable) {
                queryClient.refetchQueries({ queryKey: ['apiCompraGet'] });
                queryClient.refetchQueries({ queryKey: ['apiCompraValoresTotais'] });
            }

            notification.success({
                message: 'Feito!',
                description: 'Compra excluída!',
            });
        } catch (error) {
            dispatch.compras.setState({
                remove: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async lancarCompra(
        payload: {
            com_idpk: number;
            data_faturamento: dayjs.Dayjs;
            data_financeiro: dayjs.Dayjs;
            updateTable?: boolean;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.compras.setState({
            lancarCompra: startReduxFn(state.compras.lancarCompra.data),
        });

        const { com_idpk, data_faturamento, data_financeiro, updateTable } = payload;

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            data_faturamento: data_faturamento.format('DD-MM-YYYY HH:mm:ss'),
            data_financeiro: data_financeiro.format('DD-MM-YYYY HH:mm:ss'),
            compra_idpk: com_idpk,
        };

        const url = buildUrl(Endpoint.CompraLancar, params);

        try {
            const response = await comTokenPost(url);

            throwIfResponseIsErr(response);

            dispatch.compras.setState({
                lancarCompra: endReduxFnOk(response.data.resumo ? response.data.resumo : undefined),
            });

            if (updateTable) {
                queryClient.refetchQueries({ queryKey: ['apiCompraGet'] });
                queryClient.refetchQueries({ queryKey: ['apiCompraValoresTotais'] });
            }
        } catch (error) {
            dispatch.compras.setState({
                lancarCompra: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description:
                    (error as { message: string })?.message || 'Não foi possível lançar a compra!',
            });
        }
    },

    async resumoLancamento(payload: { com_idpk: number }, state: RootState): Promise<void> {
        dispatch.compras.setState({
            resumoLancamento: startReduxFn(state.compras.resumoLancamento.data),
        });

        const { com_idpk } = payload;

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            compra_idpk: com_idpk,
        };

        const url = buildUrl(Endpoint.CompraResumoLancamento, params);

        try {
            const response = await comTokenGet(url);

            throwIfResponseIsErr(response);

            dispatch.compras.setState({
                resumoLancamento: endReduxFnOk(
                    response.data.resumo ? response.data.resumo : response.data || null,
                ),
            });
        } catch (error) {
            dispatch.compras.setState({
                resumoLancamento: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: (error as { message: string })?.message,
            });
        }
    },

    async uploadCompra(payload: { file: File }, state: RootState): Promise<void> {
        dispatch.compras.setState({
            uploadCompra: startReduxFn(state.compras.uploadCompra.data),
        });

        const { file } = payload;

        try {
            const base64 = await fileToBase64(file);

            if (typeof base64 !== 'string') {
                throw new Error('Falha ao tentar fazer upload!');
            }

            const params = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                extensao: 'xml',
                somente_validar: 'S',
            };

            const url = buildUrl(Endpoint.ImportacaoNFERecebida, params);

            const response = await comTokenPost(url, {
                file_name: file.name,
                file_base64: base64,
            });

            throwIfResponseIsErr(response);

            dispatch.compras.setState({
                uploadCompra: endReduxFnOk(
                    response.data.registros ? response.data.registros[0] : undefined,
                ),
            });

            notification.success({
                message: 'Feito!',
                description: 'Dados cadastrados',
            });
        } catch (error) {
            dispatch.compras.setState({
                uploadCompra: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: (error as { message: string })?.message,
            });
        }
    },

    imprimirCompra(payload: { link: string; open: boolean; tipo: number }, state: RootState): void {
        dispatch.compras.setState({
            imprimirCompra: startReduxFn(state.compras.imprimirCompra.data),
        });

        const { link, open, tipo } = payload;

        try {
            if (tipo !== 2) {
                if (open) {
                    openPDF({
                        access: 'autenticado',
                        type: 'link',
                        link,
                    });
                } else {
                    const filename = link.split('/').at(-1) || 'file.xml';
                    downloadFile(link, filename);
                }
            } else {
                window.open(link, '_blank');
            }

            dispatch.compras.setState({
                imprimirCompra: endReduxFnOk(null),
            });
        } catch (error) {
            dispatch.compras.setState({
                imprimirCompra: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description:
                    (error as { message: string })?.message || 'Não foi Possível Gerar o Arquivo',
            });
        }
    },

    async estornarCompra(
        payload: {
            com_idpk: number;
            updateTable?: boolean;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.compras.setState({
            estornarCompra: startReduxFn(state.compras.estornarCompra.data),
        });

        const { com_idpk, updateTable } = payload;

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            // única diferença entre esta e a fn lançar
            compra_idpk: com_idpk,
        };

        const url = buildUrl(Endpoint.CompraEstornar, params);

        try {
            const response = await comTokenPost(url);

            throwIfResponseIsErr(response);

            dispatch.compras.setState({
                estornarCompra: endReduxFnOk(null),
            });

            if (updateTable) {
                queryClient.refetchQueries({ queryKey: ['apiCompraGet'] });
                queryClient.refetchQueries({ queryKey: ['apiCompraValoresTotais'] });
            }

            notification.success({
                message: 'Sucesso!',
                description: 'Compra estornada com sucesso!',
            });
        } catch (error) {
            dispatch.compras.setState({
                estornarCompra: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description:
                    (error as { message: string })?.message ||
                    'Não foi possível estornar a compra!',
            });
        }
    },

    async carregarManifestacao(payload: { com_idpk: number }, state: RootState): Promise<void> {
        dispatch.compras.setState({
            carregarManifestacao: startReduxFn(state.compras.carregarManifestacao.data),
        });

        const { com_idpk } = payload;

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            manifesto_dfe_idpk: com_idpk,
        };

        const url = buildUrl(Endpoint.CompraCarregarManifestada, params);

        try {
            const response = await comTokenPost(url);

            throwIfResponseIsErr(response);

            dispatch.compras.setState({
                carregarManifestacao: endReduxFnOk(
                    response.data ? (response.data as TReturnManifestadoCarregar) : null,
                ),
            });

            notification.success({
                message: 'Sucesso!',
                description: 'Compra importada com sucesso!',
            });
        } catch (error) {
            dispatch.compras.setState({
                carregarManifestacao: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: (error as { message: string })?.message,
            });
        }
    },

    async manifestar(
        payload: { com_idpk: number; tipoManifesto: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        dispatch.compras.setState({
            manifestar: startReduxFn(state.compras.manifestar.data),
        });

        const { com_idpk, tipoManifesto, updateTable, ...rest } = payload;

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            manifesto_dfe_idpk: com_idpk,
            tipo_manifesto: tipoManifesto,
            ...rest,
        };

        const url = buildUrl(Endpoint.ManifestadorManifestar, params);

        try {
            const response = await comTokenPost(url);

            throwIfResponseIsErr(response);

            dispatch.compras.setState({
                manifestar: endReduxFnOk('Success'),
            });

            if (updateTable) {
                queryClient.refetchQueries({ queryKey: ['apiManifestadorDfeConsultar'] });
                queryClient.refetchQueries({ queryKey: ['apiManifestadorDfeValoresTotais'] });
            }
        } catch (error) {
            dispatch.compras.setState({
                manifestar: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: (error as { message: string })?.message,
            });
        }
    },

    async buscarNaReceita(_, state: RootState): Promise<void> {
        dispatch.compras.setState({
            buscarNaReceita: startReduxFn(state.compras.buscarNaReceita.data),
        });

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
        };

        const url = buildUrl(Endpoint.ManifestadorBuscarNaReceita, params);

        try {
            const response = await comTokenGet(url);

            throwIfResponseIsErr(response);

            queryClient.refetchQueries({ queryKey: ['apiManifestadorDfeConsultar'] });
            queryClient.refetchQueries({ queryKey: ['apiManifestadorDfeValoresTotais'] });

            dispatch.compras.setState({
                buscarNaReceita: endReduxFnOk(
                    response.data.registros ? response.data.registros[0] : response.data || null,
                ),
            });
        } catch (error) {
            dispatch.compras.setState({
                buscarNaReceita: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: (error as { message: string })?.message,
            });
        }
    },
});
