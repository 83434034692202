import { notification } from 'antd';
import dayjs from 'dayjs';
import { useLoginStore } from 'features/login/store';
import { canUserListAllVendedores } from 'features/login/util';
import {
    type ApiRelatorioBoletosRecebidosParams,
    apiRelatorioBoletosRecebidos,
} from 'features/relatorio/api/apiRelatorioBoletosRecebidos';
import { apiRelatorioClienteAniversariante } from 'features/relatorio/api/apiRelatorioClienteAniversariante';
import type { RootDispatch, RootState } from 'state/store';
import { comTokenGet } from 'std/api/comToken';
import type { ApiResponse } from 'std/api/types';
import { throwIfResponseIsErr } from 'std/api/util';
import { type BuildUrlParams, buildUrl } from 'std/api2/buildUrl';
import { DATE_FORMAT } from 'std/const/date';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import type { ApiRelatorioFinanceiroMovimentoContasPagarParams } from 'std/types/api/relatorio/ApiRelatorioFinanceiroMovimentoContasPagar';
import type { ApiRelatorioFinanceiroMovimentoContasReceberParams } from 'std/types/api/relatorio/ApiRelatorioFinanceiroMovimentoContasReceber';
import type { ApiRelatorioFinanceiroMovimentoFluxoCaixaParams } from 'std/types/api/relatorio/ApiRelatorioFinanceiroMovimentoFluxoCaixaParams';
import type {
    ApiRelatorioFinanceiroMovimentoLancamentosPagar,
    ApiRelatorioFinanceiroMovimentoLancamentosPagarParams,
} from 'std/types/api/relatorio/ApiRelatorioFinanceiroMovimentoLancamentosPagar';
import type {
    ApiRelatorioFinanceiroMovimentoLancamentosReceber,
    ApiRelatorioFinanceiroMovimentoLancamentosReceberParams,
} from 'std/types/api/relatorio/ApiRelatorioFinanceiroMovimentoLancamentosReceber';
import { Endpoint } from 'std/types/enum/endpoint';
import type {
    RelatorioFinanceiroBalancete,
    RelatorioFinanceiroDRE,
    RelatorioFinanceiroEntradaSaidaCaixa,
    RelatorioFinanceiroFluxoCaixa,
    RelatorioFinanceiroFluxoCaixaRegistro,
    RelatorioFinanceiroTotaisCategoria,
    RelatorioFinanceiroValoresRecebidos,
    RelatorioNotaFiscalCliente,
    RelatorioNotaFiscalProduto,
    RelatorioVendaComissaoMovimento,
    RelatorioVendaRelacaoClienteVendedor,
} from './types';

export const effects = (dispatch: RootDispatch) => ({
    async getRelatorioNotaFiscalProduto(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioNotaFiscalProduto: startReduxFn(),
        });

        try {
            const {
                filterProduto,
                filterIncluirInvalidas,
                filterIntervalo,
                filterMarcador,
                filterTiposNfe,
                filterVendedorExterno,
            } = state.relatorios;

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                pro_idpk: filterProduto?.pro_idpk,
                data_de: filterIntervalo?.start.format('DD-MM-YYYY'),
                data_ate: filterIntervalo?.end.format('DD-MM-YYYY'),
                notas_nao_emitidas: filterIncluirInvalidas ? 'S' : 'N',
                marcadores: filterMarcador,
                tipo_nota: filterTiposNfe?.map((tip) => tip.value).toString(),
                venda_externa_idpk: filterVendedorExterno?.vee_idpk,
            };

            const url = buildUrl(Endpoint.RelatorioNotaFiscalProduto, params);
            const response: ApiResponse = await comTokenGet(url, params);
            throwIfResponseIsErr(response);

            const registros: RelatorioNotaFiscalProduto[] = response.data.registros || [];

            dispatch.relatorios.setState({
                getRelatorioNotaFiscalProduto: endReduxFnOk(registros),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioNotaFiscalProduto: endReduxFnError(error),
            });
        }
    },

    async getRelatorioNotaFiscalCliente(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioNotaFiscalCliente: startReduxFn(),
        });

        try {
            const {
                filterCliente,
                filterIncluirInvalidas,
                filterIntervalo,
                filterMarcador,
                filterTiposNfe,
                filterVendedorExterno,
            } = state.relatorios;

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                cli_idpk: filterCliente?.cli_idpk,
                data_de: filterIntervalo?.start.format('DD-MM-YYYY'),
                data_ate: filterIntervalo?.end.format('DD-MM-YYYY'),
                notas_nao_emitidas: filterIncluirInvalidas ? 'S' : 'N',
                marcadores: filterMarcador,
                tipo_nota: filterTiposNfe?.map((tip) => tip.value).toString(),
                venda_externa_idpk: filterVendedorExterno?.vee_idpk,
            };

            const url = buildUrl(Endpoint.RelatorioNotaFiscalCliente, params);
            const response: ApiResponse = await comTokenGet(url, params);
            throwIfResponseIsErr(response);

            const registros: RelatorioNotaFiscalCliente[] = response.data.registros || [];

            dispatch.relatorios.setState({
                getRelatorioNotaFiscalCliente: endReduxFnOk(registros),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioNotaFiscalCliente: endReduxFnError(error),
            });
        }
    },

    async getRelatorioNotaFiscalTipo(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioNotaFiscalTipo: startReduxFn(),
        });

        try {
            const {
                filterCliente,
                filterIncluirInvalidas,
                filterIntervalo,
                filterTiposNfe,
                filterMarcador,
                filterVendedorExterno,
            } = state.relatorios;

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                cli_idpk: filterCliente?.cli_idpk,
                data_de: filterIntervalo?.start.format('DD-MM-YYYY'),
                data_ate: filterIntervalo?.end.format('DD-MM-YYYY'),
                notas_nao_emitidas: filterIncluirInvalidas ? 'S' : 'N',
                tipo_nota: filterTiposNfe?.map((tip) => tip.value).toString(),
                marcadores: filterMarcador,
                venda_externa_idpk: filterVendedorExterno?.vee_idpk,
            };

            const url = buildUrl(Endpoint.RelatorioNotaFiscalTipo, params);
            const response: ApiResponse = await comTokenGet(url, params);
            throwIfResponseIsErr(response);

            const registros = response.data.registros || [];

            dispatch.relatorios.setState({
                getRelatorioNotaFiscalTipo: endReduxFnOk(registros),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioNotaFiscalTipo: endReduxFnError(error),
            });
        }
    },

    async getRelatorioNotaFiscalItens(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioNotaFiscalItens: startReduxFn(),
        });

        try {
            const {
                filterIntervalo,
                filterTiposNfe,
                filterStatusNfe,
                filterMarcador,
                filterVendedorExterno,
            } = state.relatorios;

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                data_de: filterIntervalo?.start.format('DD-MM-YYYY'),
                data_ate: filterIntervalo?.end.format('DD-MM-YYYY'),
                tipo_nota: filterTiposNfe?.map((tip) => tip.value).toString(),
                status: filterStatusNfe?.map((status) => status.value).toString(),
                marcadores: filterMarcador,
                venda_externa_idpk: filterVendedorExterno?.vee_idpk,
            };

            const url = buildUrl(Endpoint.RelatorioNotaFiscalItens, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const registros = response.data.registros || [];

            dispatch.relatorios.setState({
                getRelatorioNotaFiscalItens: endReduxFnOk(registros),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioNotaFiscalItens: endReduxFnError(error),
            });
        }
    },

    async getRelatorioFinanceiroContasReceber(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioFinanceiroContasReceber: startReduxFn(),
        });

        try {
            const {
                filterClienteCR,
                filterEmissao,
                filterVencimento,
                filterSituacao,
                filterCategoria,
                filterConta,
                filterMarcador,
                orderby,
            } = state.relatorios;

            const params: ApiRelatorioFinanceiroMovimentoContasReceberParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                cliente: filterClienteCR?.fmo_sacado_nome,
                data_emissao_de: filterEmissao?.start.format('DD-MM-YYYY'),
                data_emissao_ate: filterEmissao?.end.format('DD-MM-YYYY'),
                data_vencimento_de: filterVencimento?.start.format('DD-MM-YYYY'),
                data_vencimento_ate: filterVencimento?.end.format('DD-MM-YYYY'),
                situacao: filterSituacao,
                financeiro_categoria_idpk: filterCategoria?.fca_idpk,
                financeiro_conta_idpk: filterConta?.fco_idpk,
                marcadores: filterMarcador,
                orderby,
            };

            const url = buildUrl(Endpoint.RelatorioFinanceiroMovimentoContasReceber, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const registros = response.data.registros || [];

            dispatch.relatorios.setState({
                getRelatorioFinanceiroContasReceber: endReduxFnOk(registros),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioFinanceiroContasReceber: endReduxFnError(error),
            });
        }
    },

    async getRelatorioFinanceiroContasPagar(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioFinanceiroContasPagar: startReduxFn(),
        });

        try {
            const {
                filterEmissao,
                filterVencimento,
                filterSituacao,
                filterCategoria,
                filterConta,
                filterMarcador,
            } = state.relatorios;

            const params: ApiRelatorioFinanceiroMovimentoContasPagarParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                data_emissao_de: filterEmissao?.start.format('DD-MM-YYYY'),
                data_emissao_ate: filterEmissao?.end.format('DD-MM-YYYY'),
                data_vencimento_de: filterVencimento?.start.format('DD-MM-YYYY'),
                data_vencimento_ate: filterVencimento?.end.format('DD-MM-YYYY'),
                situacao: filterSituacao,
                financeiro_categoria_idpk: filterCategoria?.fca_idpk,
                financeiro_conta_idpk: filterConta?.fco_idpk,
                marcadores: filterMarcador,
            };

            const url = buildUrl(Endpoint.RelatorioFinanceiroMovimentoContasPagar, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const registros = response.data.registros || [];

            dispatch.relatorios.setState({
                getRelatorioFinanceiroContasPagar: endReduxFnOk(registros),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioFinanceiroContasPagar: endReduxFnError(error),
            });
        }
    },

    async getRelatorioFinanceiroLancamentosReceber(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioFinanceiroLancamentosReceber: startReduxFn(),
        });

        try {
            const {
                filterIntervalo,
                filterTipoLancamento,
                filterCategoria,
                filterConta,
                filterMarcador,
            } = state.relatorios;

            const params: ApiRelatorioFinanceiroMovimentoLancamentosReceberParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                data_de: filterIntervalo?.start.format('DD-MM-YYYY'),
                data_ate: filterIntervalo?.end.format('DD-MM-YYYY'),
                tipo_lancamento: filterTipoLancamento,
                financeiro_categoria_idpk: filterCategoria?.fca_idpk,
                financeiro_conta_idpk: filterConta?.fco_idpk,
                marcadores: filterMarcador,
            };

            const url = buildUrl(Endpoint.RelatorioFinanceiroMovimentoLancamentosReceber, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const registros: ApiRelatorioFinanceiroMovimentoLancamentosReceber[] =
                response.data.registros || [];

            dispatch.relatorios.setState({
                getRelatorioFinanceiroLancamentosReceber: endReduxFnOk(registros),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioFinanceiroLancamentosReceber: endReduxFnError(error),
            });
        }
    },

    async getRelatorioFinanceiroLancamentosPagar(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioFinanceiroLancamentosPagar: startReduxFn(),
        });

        try {
            const { filterIntervalo, filterCategoria, filterConta, filterMarcador } =
                state.relatorios;

            const params: ApiRelatorioFinanceiroMovimentoLancamentosPagarParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                data_de: filterIntervalo?.start.format('DD-MM-YYYY'),
                data_ate: filterIntervalo?.end.format('DD-MM-YYYY'),
                financeiro_categoria_idpk: filterCategoria?.fca_idpk,
                financeiro_conta_idpk: filterConta?.fco_idpk,
                marcadores: filterMarcador,
            };

            const url = buildUrl(Endpoint.RelatorioFinanceiroMovimentoLancamentosPagar, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const registros: ApiRelatorioFinanceiroMovimentoLancamentosPagar[] =
                response.data.registros || [];

            dispatch.relatorios.setState({
                getRelatorioFinanceiroLancamentosPagar: endReduxFnOk(registros),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioFinanceiroLancamentosPagar: endReduxFnError(error),
            });
        }
    },

    async getRelatorioEstoqueSaldo(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioEstoqueSaldo: startReduxFn(),
        });

        try {
            const {
                filterDia,
                orderby,
                filterCategoria,
                filterMarcador,
                filterExibirPreco,
                filterTipoPreco,
                filterTabelaPreco,
                filterTipoEstoque,
                filterTipoProduto,
            } = state.relatorios;

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                data_ate: filterDia?.format('DD-MM-YYYY'),
                orderby,
                marcadores: filterMarcador,
                tipo_estoque: filterTipoEstoque,
                produto_tipo: filterTipoProduto,
                categorias: filterCategoria?.fca_idpk,
            };

            // Se for para exibir o valor, for valor de venda, e não for o valor do cadastro
            // então requisita a tabela de preço
            if (
                filterExibirPreco &&
                filterTipoPreco === 'preco_venda' &&
                filterTabelaPreco?.idpk !== 0
            ) {
                params.tabela_preco_idpk = filterTabelaPreco?.idpk;
            }

            const url = buildUrl(Endpoint.RelatorioEstoqueSaldo, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const registros = response.data.registros || [];

            dispatch.relatorios.setState({
                getRelatorioEstoqueSaldo: endReduxFnOk(registros),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioEstoqueSaldo: endReduxFnError(error),
            });
        }
    },

    async getRelatorioEstoqueMinimo(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioEstoqueMinimo: startReduxFn(),
        });

        try {
            const { orderby, filterMarcador, filterTipoProduto } = state.relatorios;

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                orderby,
                marcadores: filterMarcador,
                produto_tipo: filterTipoProduto,
            };

            const url = buildUrl(Endpoint.RelatorioEstoqueMinimo, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const registros = response.data.registros || [];

            dispatch.relatorios.setState({
                getRelatorioEstoqueMinimo: endReduxFnOk(registros),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioEstoqueMinimo: endReduxFnError(error),
            });
        }
    },

    async getRelatorioVendaRelacao(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioVendaRelacao: startReduxFn(),
        });

        try {
            const {
                filterTipoIntervalo,
                filterIntervalo,
                filterCliente,
                filterVendedor,
                filterMarcador,
                filterTipoVenda,
                filterCategoria,
                orderby,
            } = state.relatorios;

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                data_tipo: filterTipoIntervalo,
                data_de: filterIntervalo?.start.format('DD-MM-YYYY'),
                data_ate: filterIntervalo?.end.format('DD-MM-YYYY'),
                vendedor_idpk: filterVendedor?.ven_idpk,
                cliente_idpk: filterCliente?.cli_idpk,
                marcadores: filterMarcador,
                tipo: filterTipoVenda,
                financeiro_categoria_idpk: filterCategoria?.fca_idpk,
                orderby,
            };

            const url = buildUrl(Endpoint.RelatorioVendaRelacao, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            dispatch.relatorios.setState({
                getRelatorioVendaRelacao: endReduxFnOk(response.data.registros || null),
            });
        } catch (err) {
            dispatch.relatorios.setState({
                getRelatorioVendaRelacao: endReduxFnError(err),
            });
        }
    },

    async getRelatorioVendaRelacaoResumido(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioVendaRelacaoResumido: startReduxFn(),
        });

        try {
            const {
                filterTipoIntervalo,
                filterIntervalo,
                filterCliente,
                filterVendedor,
                filterMarcador,
                filterCategoria,
                orderby,
            } = state.relatorios;

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                orderby,
                cliente_idpk: filterCliente?.cli_idpk,
                vendedor_idpk: filterVendedor?.ven_idpk,
                marcadores: filterMarcador,
                financeiro_categoria_idpk: filterCategoria?.fca_idpk,
            };

            if (filterTipoIntervalo) {
                params[`${filterTipoIntervalo}_de`] = filterIntervalo?.start.format(DATE_FORMAT);
                params[`${filterTipoIntervalo}_ate`] = filterIntervalo?.end.format(DATE_FORMAT);
            }

            const url = buildUrl(Endpoint.RelatorioVendaRelacaoResumido, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            dispatch.relatorios.setState({
                getRelatorioVendaRelacaoResumido: endReduxFnOk(response.data.registros || null),
            });
        } catch (err) {
            dispatch.relatorios.setState({
                getRelatorioVendaRelacaoResumido: endReduxFnError(err),
            });
        }
    },

    async getRelatorioVendaComissao(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioVendaComissao: startReduxFn(),
        });

        const { filterVendedores, filterMes, listarComissoesPendentes } = state.relatorios;

        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
            ...(canUserListAllVendedores()
                ? {
                      usuarios_list: JSON.stringify(
                          filterVendedores?.map((vendedor) => vendedor.value),
                      ),
                  }
                : { usuarios_list: JSON.stringify([useLoginStore.getState().login?.usu_idpk]) }),
            mes_referencia: (filterMes || dayjs()).format('MM-YYYY'),
            listar_comissoes_pendentes: listarComissoesPendentes ? 'S' : 'N',
        };

        const url = buildUrl(Endpoint.RelatorioComissaoMovimento, params);

        try {
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const comissoes = response.data
                .registros as unknown as RelatorioVendaComissaoMovimento[];
            dispatch.relatorios.setState({
                getRelatorioVendaComissao: endReduxFnOk(comissoes),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioVendaComissao: endReduxFnError(error),
            });
        }
    },

    async getRelatorioVendaRelacaoClienteVendedor(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioVendaRelacaoClienteVendedor: startReduxFn(),
        });

        try {
            const {
                filterIntervalo,
                filterCliente,
                filterVendedor,
                filterMarcador,
                filterCategoria,
                orderby,
            } = state.relatorios;

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                cliente_idpk: filterCliente?.cli_idpk,
                vendedor_idpk: filterVendedor?.ven_idpk,
                data_de: filterIntervalo?.start.format('DD-MM-YYYY'),
                data_ate: filterIntervalo?.end.format('DD-MM-YYYY'),
                marcadores: filterMarcador,
                financeiro_categoria_idpk: filterCategoria?.fca_idpk,
                orderby,
            };

            const url = buildUrl(Endpoint.RelatorioVendaRelacaoClienteVendedor, params);
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const registros: RelatorioVendaRelacaoClienteVendedor[] = response.data.registros || [];

            dispatch.relatorios.setState({
                getRelatorioVendaRelacaoClienteVendedor: endReduxFnOk(registros),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioVendaRelacaoClienteVendedor: endReduxFnError(error),
            });
        }
    },

    async getRelatorioFinanceiroFluxoCaixa(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioFinanceiroFluxoCaixa: startReduxFn(),
        });

        try {
            const { filterVencimento, filterCategoria, filterConta, filterMarcador } =
                state.relatorios;

            if (!filterVencimento) {
                notification.error({
                    message: 'Falha ao gerar relatório',
                    description: 'Este relatório precisa do período de vencimento',
                });

                throw new Error('Vencimento não informado');
            }

            const params: ApiRelatorioFinanceiroMovimentoFluxoCaixaParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                data_vencimento_de: filterVencimento?.start.format('DD-MM-YYYY'),
                data_vencimento_ate: filterVencimento?.end.format('DD-MM-YYYY'),
                financeiro_categoria_idpk: filterCategoria?.fca_idpk,
                financeiro_conta_idpk: filterConta?.fco_idpk,
                marcadores: filterMarcador,
            };

            const url = buildUrl(Endpoint.RelatorioFinanceiroMovimentoFluxoCaixa, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);
            const registrosApi = response.data.registros || [];

            // Essa api retorna um array com apenas um objeto
            // Esse objeto contém dois pairs: receita / despesa
            const receitaDespesa = registrosApi[0];

            if (!(receitaDespesa?.receita && receitaDespesa.despesa)) {
                throw new Error('Retorno da API inválido');
            }

            const registros: RelatorioFinanceiroFluxoCaixaRegistro[] = [];

            for (const rec of receitaDespesa.receita) {
                const obj: RelatorioFinanceiroFluxoCaixaRegistro = {
                    hiper_categoria: 'receitas',
                    categoria: rec.categoria,
                    total: rec.total,
                };

                for (const per of rec.periodo) {
                    obj[per.periodo] = per.valor;
                }

                registros.push(obj);
            }

            for (const des of receitaDespesa.despesa) {
                const obj: RelatorioFinanceiroFluxoCaixaRegistro = {
                    hiper_categoria: 'despesas',
                    categoria: des.categoria,
                    total: des.total,
                };

                for (const per of des.periodo) {
                    obj[per.periodo] = per.valor;
                }

                registros.push(obj);
            }

            const newData: RelatorioFinanceiroFluxoCaixa = {
                registros,
                resumo: response.data.resumo,
                tipoPeriodo: response.data.tipo_periodo,
            };

            dispatch.relatorios.setState({
                getRelatorioFinanceiroFluxoCaixa: endReduxFnOk(newData),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioFinanceiroFluxoCaixa: endReduxFnError(error),
            });
        }
    },

    async getRelatorioFinanceiroBalancete(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioFinanceiroBalancete: startReduxFn(),
        });

        const { filterIntervalo, filterCategoria, filterConta, filterMarcador } = state.relatorios;

        if (!filterIntervalo) {
            return;
        }

        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
            data_de: filterIntervalo?.start.format('DD-MM-YYYY'),
            data_ate: filterIntervalo?.end.format('DD-MM-YYYY'),
            financeiro_categoria_idpk: filterCategoria?.fca_idpk,
            financeiro_conta_idpk: filterConta?.fco_idpk,
            marcadores: filterMarcador,
        };

        try {
            const url = buildUrl(Endpoint.RelatorioFinanceiroContaBalancete, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            dispatch.relatorios.setState({
                getRelatorioFinanceiroBalancete: endReduxFnOk(
                    response.data as unknown as RelatorioFinanceiroBalancete,
                ),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioFinanceiroBalancete: endReduxFnError(error),
            });
        }
    },

    async getRelatorioFinanceiroEntradaSaidaCaixa(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioFinanceiroEntradaSaidaCaixa: startReduxFn(),
        });

        const { filterIntervalo, filterCategoria, filterContaBancaria, filterMarcador } =
            state.relatorios;

        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
            financeiro_conta_idpk: filterContaBancaria?.fco_idpk,
            financeiro_categoria_idpk: filterCategoria?.fca_idpk,
            data_de: filterIntervalo?.start.format('DD-MM-YYYY'),
            data_ate: filterIntervalo?.end.format('DD-MM-YYYY'),
            marcadores: filterMarcador,
        };

        const url = buildUrl(Endpoint.RelatorioFinanceiroContaExtrato, params);

        try {
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            dispatch.relatorios.setState({
                getRelatorioFinanceiroEntradaSaidaCaixa: endReduxFnOk(
                    response.data as RelatorioFinanceiroEntradaSaidaCaixa,
                ),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioFinanceiroEntradaSaidaCaixa: endReduxFnError(error),
            });
        }
    },

    async getRelatorioFinanceiroTotaisCategoria(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioFinanceiroTotaisCategoria: startReduxFn(),
        });

        const { filterIntervalo, filterContaBancaria, filterMarcador, filterCategoria } =
            state.relatorios;

        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
            financeiro_conta_idpk: filterContaBancaria?.fco_idpk,
            financeiro_categoria_idpk: filterCategoria?.fca_idpk,
            data_de: filterIntervalo?.start.format('DD-MM-YYYY'),
            data_ate: filterIntervalo?.end.format('DD-MM-YYYY'),
            marcadores: filterMarcador,
        };

        const url = buildUrl(Endpoint.RelatorioFinanceiroContaCategorias, params);

        try {
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            dispatch.relatorios.setState({
                getRelatorioFinanceiroTotaisCategoria: endReduxFnOk(
                    response.data as RelatorioFinanceiroTotaisCategoria,
                ),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioFinanceiroTotaisCategoria: endReduxFnError(error),
            });
        }
    },

    async getRelatorioFinanceiroValoresRecebidos(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioFinanceiroValoresRecebidos: startReduxFn(),
        });

        const {
            filterIntervalo,
            filterUsuario,
            filterMarcador,
            filterSistemaIntegrado,
            filterDispositivo,
            filterTipoConsulta,
        } = state.relatorios;

        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
            usuario_idpk: filterUsuario?.usu_idpk,
            data_de: filterIntervalo?.start.format('DD-MM-YYYY HH:mm:ss'),
            data_ate: filterIntervalo?.end.format('DD-MM-YYYY HH:mm:ss'),
            sistema_idpk: filterSistemaIntegrado?.sistema_idpk,
            dis_idpk: filterDispositivo?.dis_idpk,
            marcadores: filterMarcador,
            tipo_consulta: filterTipoConsulta ? 'D' : 'R',
        };

        const url = buildUrl(Endpoint.RelatorioFinanceiroValoresRecebidosResumo, params);

        try {
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            dispatch.relatorios.setState({
                getRelatorioFinanceiroValoresRecebidos: endReduxFnOk(
                    response.data as RelatorioFinanceiroValoresRecebidos,
                ),
                showDetail: !!filterTipoConsulta,
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioFinanceiroValoresRecebidos: endReduxFnError(error),
            });
        }
    },

    async getRelatorioFinanceiroDRE(
        payload: { meses?: number[] },
        state: RootState,
    ): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioFinanceiroDRE: startReduxFn(),
        });

        const { filterAno, baseDRE, custoBaseDRE, filterMarcador, filterEmpresas } =
            state.relatorios;
        const { meses } = payload;

        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
            ano: filterAno ? filterAno.year() : dayjs().year(),
            base_geracao: baseDRE,
            base_custo: custoBaseDRE,
            forcar_geracao_meses: meses && meses.length > 0 ? `[${meses}]` : null,
            marcadores: filterMarcador,
            empresas_list:
                filterEmpresas && filterEmpresas.length > 0 ? `[${filterEmpresas}]` : null,
        };

        const url = buildUrl(Endpoint.DReGerar, params);

        try {
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            dispatch.relatorios.setState({
                getRelatorioFinanceiroDRE: endReduxFnOk(response.data as RelatorioFinanceiroDRE),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioFinanceiroDRE: endReduxFnError(error),
            });
        }
    },

    async getRelatorioFinanceiroBoletosRecebidos(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioFinanceiroBoletosRecebidos: startReduxFn(),
        });

        try {
            const { filterConta, filterIntervalo, filterTipoIntervalo, orderby } = state.relatorios;

            const params: ApiRelatorioBoletosRecebidosParams = {
                financeiro_conta_idpk: filterConta?.fco_idpk,
                data_tipo: Number(filterTipoIntervalo),
                data_de: filterIntervalo?.start.format(DATE_FORMAT),
                data_ate: filterIntervalo?.end.format(DATE_FORMAT),
                orderby,
            };

            const data = await apiRelatorioBoletosRecebidos({ params });

            dispatch.relatorios.setState({
                getRelatorioFinanceiroBoletosRecebidos: endReduxFnOk(data),
            });
        } catch (e) {
            dispatch.relatorios.setState({
                getRelatorioFinanceiroBoletosRecebidos: endReduxFnError(e),
            });
        }
    },

    async getRelatorioCadastroRelacaoCliente(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioCadastroRelacaoCliente: startReduxFn(),
        });

        try {
            const {
                filterCliente,
                filterDocumento,
                filterVendedor,
                filterDia,
                filterCidades,
                filterAgruparCidade,
            } = state.relatorios;

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                cliente_idpk: filterCliente?.cli_idpk,
                documento: filterDocumento,
                vendedor_idpk: filterVendedor?.ven_idpk,
                data_ultima_alteracao: filterDia?.format('DD-MM-YYYY'),
                cidade_idpk_list: filterCidades?.map((cid) => cid.cid_idpk),
                agrupar_por_cidade: filterAgruparCidade ? 'S' : 'N',
            };

            const url = buildUrl(Endpoint.RelatorioCadastroRelacaoCliente, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const registros = response.data.registros || [];

            dispatch.relatorios.setState({
                getRelatorioCadastroRelacaoCliente: endReduxFnOk(registros),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioCadastroRelacaoCliente: endReduxFnError(error),
            });
        }
    },

    async getRelatorioCadastroClienteAniversariante(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioCadastroClienteAniversariante: startReduxFn(),
        });

        try {
            const { filterVendedor, filterCidades, filterMesNumber, filterDiaNumber, orderby } =
                state.relatorios;

            const registros = await apiRelatorioClienteAniversariante({
                params: {
                    vendedor_idpk: filterVendedor?.ven_idpk,
                    cidade_idpk_list: filterCidades?.map((c) => c.cid_idpk),
                    aniversariante_mes: filterMesNumber?.toString(),
                    aniversariante_dia: filterDiaNumber?.toString(),
                    orderby,
                },
            });

            dispatch.relatorios.setState({
                getRelatorioCadastroClienteAniversariante: endReduxFnOk(registros),
            });
        } catch (error) {
            dispatch.relatorios.setState({
                getRelatorioCadastroClienteAniversariante: endReduxFnError(error),
            });
        }
    },

    async getRelatorioCompraFornecedor(_, state: RootState): Promise<void> {
        dispatch.relatorios.setState({
            getRelatorioCompraFornecedor: startReduxFn(),
        });

        try {
            const { filterIntervalo, filterFornecedor } = state.relatorios;

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk || 0,
                fornecedor_idpk: filterFornecedor ? filterFornecedor.for_idpk : undefined,
            };

            if (filterIntervalo) {
                params.data_de = filterIntervalo.start.format('DD-MM-YYYY');
                params.data_ate = filterIntervalo.end.format('DD-MM-YYYY');
            }

            const url = buildUrl(Endpoint.RelatorioCompraFornecedor, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const registros = response.data.registros || [];

            dispatch.relatorios.setState({
                getRelatorioCompraFornecedor: endReduxFnOk(registros),
            });
        } catch (err) {
            dispatch.relatorios.setState({
                getRelatorioCompraFornecedor: endReduxFnError(err),
            });
        }
    },
});
