import type { RootDispatch, RootState } from 'state/store';
import { semTokenGet } from 'std/api/semToken';
import type { ApiResponse } from 'std/api/types';
import { throwIfResponseIsErr } from 'std/api/util';
import { buildUrl } from 'std/api2/buildUrl';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { isLocalHost } from 'std/url';
import type { ConsultaCliente, ConsultaLayoutSistema } from './types';

const baseUrl = isLocalHost() ? 'sandbox.app.mercurioapp.com.br' : window.location.hostname;

export const effects = (dispatch: RootDispatch) => ({
    async getLayoutSistema(payload: { slug: string }, state: RootState): Promise<void> {
        dispatch.consultaPublica.setState({
            getLayoutSistema: startReduxFn(state.consultaPublica.getLayoutSistema.data),
        });

        const params = {
            url: baseUrl,
            slug: payload.slug,
        };

        const url = buildUrl('EmpresaSlug/Consultar' as any, params);

        try {
            const response: ApiResponse = await semTokenGet(url);
            throwIfResponseIsErr(response);

            dispatch.consultaPublica.setState({
                getLayoutSistema: endReduxFnOk(response.data as unknown as ConsultaLayoutSistema),
            });
        } catch (error) {
            dispatch.consultaPublica.setState({
                getLayoutSistema: endReduxFnError(error),
            });
        }
    },

    async getCobrancasPorCliente(
        payload: {
            clienteCpfCnpj: string | null;
            slug: string;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.consultaPublica.setState({
            cobrancasPorCliente: startReduxFn(state.consultaPublica.cobrancasPorCliente.data),
        });

        const params = {
            url: baseUrl,
            slug: payload.slug,
            documento: payload.clienteCpfCnpj,
        };

        // TODO: usar api nova

        const url = buildUrl('EmpresaSlug/Cliente' as any, params);

        try {
            const response: ApiResponse = await semTokenGet(url);
            throwIfResponseIsErr(response);

            const consultaCliente = response.data.registros
                ? response.data.registros[0].cliente
                : {};

            dispatch.consultaPublica.setState({
                cobrancasPorCliente: endReduxFnOk(consultaCliente as unknown as ConsultaCliente),
            });
        } catch (error) {
            dispatch.consultaPublica.setState({
                cobrancasPorCliente: endReduxFnError(error),
            });
        }
    },
});
