import type { TreeItem } from '@nosferatu500/react-sortable-tree';
import { notification } from 'antd';
import { useLoginStore } from 'features/login/store';
import type { RootDispatch, RootState } from 'state/store';
import { comTokenGet, comTokenPut, comTokenRemove } from 'std/api/comToken';
import type { ApiResponseStatus } from 'std/api/types';
import type { ApiResponse } from 'std/api/types';
import { throwIfResponseIsErr } from 'std/api/util';
import { buildUrl } from 'std/api2/buildUrl';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { uuid } from 'std/util';

export const effects = (dispatch: RootDispatch) => ({
    async get(_, state: RootState): Promise<ApiResponseStatus> {
        dispatch.financeiroCategoriaDespesa.setState({
            get: startReduxFn(state.financeiroCategoriaDespesa.get.data),
        });

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            tipo: 'Despesa',
        };

        const url = buildUrl('FinanceiroCategoria/FinanceiroCategoria', params);

        try {
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const registrosData = response.data?.registros ? response.data.registros : [];

            dispatch.financeiroCategoriaDespesa.setState({
                get: endReduxFnOk(registrosData),
            });

            return 'sucesso';
        } catch (err) {
            dispatch.financeiroCategoriaDespesa.setState({
                get: endReduxFnError(err),
            });

            notification.error({
                message: 'Falhou!',
                description: 'Não foi possível buscar dados de categorias',
            });

            return 'erro';
        }
    },

    async getTreeViewData(_, state: RootState): Promise<ApiResponseStatus> {
        dispatch.financeiroCategoriaDespesa.setState({
            getTreeViewData: startReduxFn(state.financeiroCategoriaDespesa.getTreeViewData.data),
        });

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            tipo: 'Despesa',
            treeview: 'S',
        };

        const url = buildUrl('FinanceiroCategoria/FinanceiroCategoria', params);

        try {
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const registrosData = response.data ? response.data.object : [];

            dispatch.financeiroCategoriaDespesa.setState({
                getTreeViewData: endReduxFnOk([...registrosData]),
            });

            return 'sucesso';
        } catch (err) {
            dispatch.financeiroCategoriaDespesa.setState({
                getTreeViewData: endReduxFnError(err),
            });

            notification.error({
                message: 'Falhou!',
                description: 'Não foi possível buscar dados de categorias',
            });

            return 'erro';
        }
    },

    async put(
        payload: {
            dados: TreeItem[];
        },
        state: RootState,
    ): Promise<ApiResponseStatus> {
        const { dados } = payload;

        dispatch.financeiroCategoriaDespesa.setState({
            put: startReduxFn(state.financeiroCategoriaDespesa.put.data),
        });
        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
        };

        const url = buildUrl('FinanceiroCategoria/FinanceiroCategoria', params, 'Despesa');

        try {
            const response: ApiResponse = await comTokenPut(url, dados);
            throwIfResponseIsErr(response);

            dispatch.financeiroCategoriaDespesa.setState({
                put: endReduxFnOk(null),
                triggerUpdate: uuid(),
            });

            return 'sucesso';
        } catch (error) {
            dispatch.financeiroCategoriaDespesa.setState({
                put: endReduxFnError(error),
            });
            return 'erro';
        }
    },

    async remove(
        payload: {
            message: string;
            position: string;
        },
        state: RootState,
    ): Promise<ApiResponseStatus> {
        const { message, position } = payload;
        dispatch.financeiroCategoriaDespesa.setState({
            remove: startReduxFn(state.financeiroCategoriaDespesa.remove.data),
        });
        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            position,
        };

        const url = buildUrl('FinanceiroCategoria/FinanceiroCategoria', params, 'Despesa');

        try {
            const response: ApiResponse = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            notification.success({
                message: 'Feito!',
                description: message || '',
            });

            dispatch.financeiroCategoriaDespesa.setState({
                remove: endReduxFnOk(null),
                triggerUpdate: uuid(),
            });

            return 'sucesso';
        } catch (error) {
            dispatch.financeiroCategoriaDespesa.setState({
                remove: endReduxFnError(error),
            });
            return 'erro';
        }
    },
});
