import { red } from '@ant-design/colors';
import { notification } from 'antd';
import type { CardProperties } from 'components/revisar/CardList';
import { useLoginStore } from 'features/login/store';
import type { RootDispatch, RootState } from 'state/store';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import type { ApiResponse } from 'std/api/types';
import { throwIfResponseIsErr } from 'std/api/util';
import { type BuildUrlParams, buildUrl } from 'std/api2/buildUrl';
import { numberToDecimal } from 'std/format';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import type { SimNao, ValorTotalItem } from 'std/types';
import { DefaultColors, TipoConsulta } from 'std/types/enum';
import { Endpoint } from 'std/types/enum/endpoint';
import { GetTypes } from 'std/types/interfaces/GetTypes';
import type {
    ContasPagarFile,
    ContasPagarPostBody,
    ContasPagarPostLancamentoBody,
    ContasPagarPutBody,
} from './types';

const DATE_FORMAT = 'DD-MM-YYYY';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            type: GetTypes;
            customUrl?: string;
            conta_idpk?: number;
            tipo_consulta?: TipoConsulta;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.contasPagar.setState({
            get: startReduxFn(state.contasPagar.get.data),
        });

        try {
            const { type, customUrl, conta_idpk, tipo_consulta = TipoConsulta.Resumida } = payload;

            const { contasPagar } = state;

            const {
                filterStatus,
                filterSituacao,
                filterFornecedor,
                filterPesquisar,
                filterValor,
                filterCategoria,
                filterConta,
                filterDataEmissao,
                filterDataVencimento,
                filterDataQuitacao,
                filterDataCancelamento,
                filterDataRecebimento,
                filterMarcador,
                filterExcluidos,
                selectAll,
                pagination,
                sortParams,
            } = contasPagar;

            // paginação precisa ser zerada antes de criar params
            if (
                type === GetTypes.recordFiltering ||
                type === GetTypes.addRecord ||
                type === GetTypes.singleRecord ||
                type === GetTypes.totalizadores
            ) {
                dispatch.contasPagar.setState({
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: 0,
                    },
                });
            }

            let params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                total_registros: type === GetTypes.paginationChange ? 'N' : 'S',
                registro_inicial: (pagination.current - 1) * (pagination.pageSize || 0),
                qtde_registros: pagination?.pageSize || 10,
                tipo_consulta,
                marcadores: filterMarcador,
                pesquisar: filterPesquisar,
                financeiro_categoria_idpk: filterCategoria?.fca_idpk,
                financeiro_conta_idpk: filterConta?.fco_idpk,
                ...(sortParams.shouldSort
                    ? { orderby: `${sortParams.fieldName} ${sortParams.orderDirection}` }
                    : { orderby: 'fmo_data_vencimento desc' }),
            };

            let status: string[] | undefined;

            if (!filterStatus || filterStatus[0] === 'Total' || filterStatus.length === 0) {
                status = undefined;
            } else {
                status = filterStatus;
            }

            const where = filterFornecedor?.for_idpk
                ? `fmo_fornecedor_idpk=${filterFornecedor.for_idpk}`
                : '';

            params = {
                ...params,
                ...(where.length > 0 && { where }),
                status,
            };

            if (filterDataEmissao) {
                params.data_emissao_de = filterDataEmissao.start.format(DATE_FORMAT);
                params.data_emissao_ate = filterDataEmissao.end.format(DATE_FORMAT);
            }

            if (filterDataVencimento) {
                params.data_vencimento_de = filterDataVencimento.start.format(DATE_FORMAT);
                params.data_vencimento_ate = filterDataVencimento.end.format(DATE_FORMAT);
            }

            if (filterDataQuitacao) {
                params.data_quitacao_de = filterDataQuitacao.start.format(DATE_FORMAT);
                params.data_quitacao_ate = filterDataQuitacao.end.format(DATE_FORMAT);
            }

            if (filterDataCancelamento) {
                params.data_cancelamento_de = filterDataCancelamento.start.format(DATE_FORMAT);
                params.data_cancelamento_ate = filterDataCancelamento.end.format(DATE_FORMAT);
            }

            if (filterDataRecebimento) {
                params.data_recebimento_de = filterDataRecebimento.start.format(DATE_FORMAT);
                params.data_recebimento_ate = filterDataRecebimento.end.format(DATE_FORMAT);
            }

            if (filterValor) {
                params.valor_de = numberToDecimal(filterValor.start);
                params.valor_ate = numberToDecimal(filterValor.end);
            }

            if (filterSituacao && filterSituacao !== 0) {
                params.situacao = filterSituacao;
            }

            if (filterExcluidos) {
                params.excluidos = 'S';
            }

            if (selectAll) {
                params.qtde_registros = undefined;
            }

            let url = '';

            if (type === GetTypes.customUrl && customUrl) {
                url = customUrl;
            } else if (type === GetTypes.singleRecord && conta_idpk) {
                url = buildUrl(Endpoint.FinanceiroMovimentoContaPagar, params, conta_idpk);
            } else {
                url = buildUrl(Endpoint.FinanceiroMovimentoContaPagar, params);
            }

            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            dispatch.contasPagar.setState({
                get: endReduxFnOk(response.data.registros || []),
            });

            // paginação e filtros devem ser zerados após um get que alteraria a qtde de paginas
            if (
                type === GetTypes.firstGet ||
                type === GetTypes.addRecord ||
                type === GetTypes.totalizadores
            ) {
                dispatch.contasPagar.setState({
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: response.data.total_registros
                            ? Number(response.data.total_registros)
                            : 0,
                        showTotal: () => `Total de Registros: ${response.data.total_registros}`,
                        showSizeChanger: Number(response.data.total_registros) > 10,
                    },
                });
            }

            // a paginação que já existe no state deve ser mantida, mas o total/showTotal deve mudar
            if (type === GetTypes.deleteRecord || type === GetTypes.recordFiltering) {
                dispatch.contasPagar.setState({
                    pagination: {
                        ...pagination,
                        total: response.data.total_registros
                            ? Number(response.data.total_registros)
                            : 0,
                        showTotal: () => `Total de Registros: ${response.data.total_registros}`,
                        showSizeChanger: Number(response.data.total_registros) > 10,
                    },
                });
            }

            // todo get, exceto por paginacao, totalizadores e first-get com totalizador já cheio
            // (no caso de uma troca entre vendas-orçamentos-pedidos), deve gerar um novo totalizador
            if (
                [
                    GetTypes.addRecord,
                    GetTypes.deleteRecord,
                    GetTypes.editRecord,
                    GetTypes.recordFiltering,
                ].includes(type) ||
                (type === GetTypes.firstGet &&
                    contasPagar.getTotais.data &&
                    contasPagar.getTotais.data.length === 0)
            ) {
                dispatch.contasPagar.getTotais(null);
            }
        } catch (error) {
            dispatch.contasPagar.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async getOne(payload: { conta_idpk?: number }, state: RootState): Promise<void> {
        dispatch.contasPagar.setState({
            getOne: startReduxFn(state.contasPagar.getOne.data),
        });

        const { conta_idpk } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.FinanceiroMovimentoContaPagar, params, conta_idpk);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            dispatch.contasPagar.setState({
                getOne: endReduxFnOk(registros && registros.length > 0 ? registros[0] : null),
            });
        } catch (error) {
            dispatch.contasPagar.setState({
                getOne: endReduxFnError(error),
            });
        }
    },

    async post(
        payload: {
            agrupar: SimNao;
            body: ContasPagarPostBody;
            files: ContasPagarFile[];
        },
        state: RootState,
    ): Promise<void> {
        dispatch.contasPagar.setState({
            post: startReduxFn(state.contasPagar.post.data),
        });

        try {
            const { body, agrupar, files } = payload;
            const empresa_idpk = useLoginStore.getState().empresaIdpk;

            const url = buildUrl(Endpoint.FinanceiroMovimentoContaPagar, {
                empresa_idpk,
                agrupar,
            });

            const response: ApiResponse = await comTokenPost(url, body);
            throwIfResponseIsErr(response);

            const financeiro_movimento_idpk: number = response.data.registros?.[0]
                ? response.data.registros[0].fmo_idpk
                : 0;

            dispatch.contasPagar.postAnexo({
                financeiro_movimento_idpk,
                files,
            });

            notification.success({
                message: 'Feito!',
                description: 'Conta cadastrada com sucesso',
            });

            dispatch.contasPagar.setState({
                post: endReduxFnOk(financeiro_movimento_idpk),
                showModal: false,
            });

            dispatch.contasPagar.get({
                type: GetTypes.addRecord,
            });
        } catch (error) {
            dispatch.contasPagar.setState({
                post: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async put(
        payload: {
            conta_idpk: number;
            body: ContasPagarPutBody;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.contasPagar.setState({
            put: startReduxFn(state.contasPagar.put.data),
        });

        try {
            const { conta_idpk, body } = payload;

            const params = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                grupo_parcelas_alterar: 'U',
            };

            const url = buildUrl(Endpoint.FinanceiroMovimentoContaPagar, params, conta_idpk);
            const response: ApiResponse = await comTokenPut(url, body);
            throwIfResponseIsErr(response);

            notification.success({
                message: 'Feito!',
                description: 'Conta atualizada com sucesso',
            });

            dispatch.contasPagar.get({
                type: GetTypes.editRecord,
            });

            dispatch.contasPagar.setState({
                put: endReduxFnOk('success'),
                showModal: false,
            });
        } catch (error) {
            dispatch.contasPagar.setState({
                put: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: 'Falha ao atualizar conta',
            });
        }
    },

    async estornar(payload: {
        fmo_idpk: number;
        data_estorno?: string;
    }): Promise<void> {
        dispatch.contasPagar.setState({
            estornar: startReduxFn(),
        });

        try {
            const { fmo_idpk, data_estorno } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk, data_estorno };
            const url = buildUrl(Endpoint.FinanceiroMovimentoEstornar, params, fmo_idpk);
            const response: ApiResponse = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.contasPagar.get({
                type: GetTypes.editRecord,
            });

            dispatch.contasPagar.setState({
                estornar: endReduxFnOk(null),
            });
        } catch (error) {
            dispatch.contasPagar.setState({
                estornar: endReduxFnError(error),
            });

            notification.error({
                message: 'Falha ao estornar conta',
                description: error.message,
            });
        }
    },

    async getTotais(_, state: RootState): Promise<void> {
        dispatch.contasPagar.setState({
            getTotais: startReduxFn(state.contasPagar.getTotais.data),
        });

        try {
            const { contasPagar } = state;

            const {
                filterStatus,
                filterSituacao,
                filterFornecedor,
                filterPesquisar,
                filterValor,
                filterCategoria,
                filterDataEmissao,
                filterDataVencimento,
                filterDataQuitacao,
                filterDataCancelamento,
                filterDataRecebimento,
                filterMarcador,
                filterExcluidos,
                selectAll,
                pagination,
            } = contasPagar;

            let status: string[] | undefined;

            if (!filterStatus || filterStatus[0] === 'Total' || filterStatus.length === 0) {
                status = undefined;
            } else {
                status = filterStatus;
            }

            const where = filterFornecedor?.for_idpk
                ? `fmo_cliente_idpk=${filterFornecedor.for_idpk}`
                : '';

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                registro_inicial: (pagination.current - 1) * (pagination.pageSize || 0),
                qtde_registros: selectAll ? undefined : pagination.pageSize || 10,
                ...(where.length > 0 && { where }),
                status,
                financeiro_categoria_idpk: filterCategoria ? filterCategoria.fca_idpk : undefined,
                orderby: 'fmo_data_vencimento',
                tipo: 'P',
                marcadores: filterMarcador,
                pesquisar: filterPesquisar,
            };

            if (filterDataEmissao) {
                params.data_emissao_de = filterDataEmissao.start.format(DATE_FORMAT);
                params.data_emissao_ate = filterDataEmissao.end.format(DATE_FORMAT);
            }

            if (filterDataVencimento) {
                params.data_vencimento_de = filterDataVencimento.start.format(DATE_FORMAT);
                params.data_vencimento_ate = filterDataVencimento.end.format(DATE_FORMAT);
            }

            if (filterDataQuitacao) {
                params.data_quitacao_de = filterDataQuitacao.start.format(DATE_FORMAT);
                params.data_quitacao_ate = filterDataQuitacao.end.format(DATE_FORMAT);
            }

            if (filterDataCancelamento) {
                params.data_cancelamento_de = filterDataCancelamento.start.format(DATE_FORMAT);
                params.data_cancelamento_ate = filterDataCancelamento.end.format(DATE_FORMAT);
            }

            if (filterDataRecebimento) {
                params.data_recebimento_de = filterDataRecebimento.start.format(DATE_FORMAT);
                params.data_recebimento_ate = filterDataRecebimento.end.format(DATE_FORMAT);
            }

            if (filterValor) {
                params.valor_de = numberToDecimal(filterValor.start);
                params.valor_ate = numberToDecimal(filterValor.end);
            }

            if (filterSituacao && filterSituacao !== 0) {
                params.situacao = filterSituacao;
            }

            if (filterExcluidos) {
                params.excluidos = 'S';
            }

            const url = buildUrl(Endpoint.FinanceiroMovimentoValoresTotais, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const cores = {
                Vencidos: DefaultColors.Red,
                'Vencem hoje': red[7],
                'A vencer': DefaultColors.Orange,
                Pagos: DefaultColors.Green,
                'Total do período': DefaultColors.Blue,
            };

            const totais: CardProperties[] = (response.data.registros || []).map(
                (total: ValorTotalItem) => ({
                    color: cores[total.status],
                    title: total.status,
                    amount: total.valor_total,
                    number: total.quantidade_total,
                    value: total.situacao?.toString() ?? '0',
                }),
            );

            dispatch.contasPagar.setState({
                getTotais: endReduxFnOk(totais),
            });
        } catch (error) {
            dispatch.contasPagar.setState({
                getTotais: endReduxFnError(error),
            });
        }
    },

    async postLancamento(
        payload: {
            financeiro_movimento_idpk: number;
            body: ContasPagarPostLancamentoBody;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.contasPagar.setState({
            postLancamento: startReduxFn(state.contasPagar.postLancamento.data),
        });

        const { financeiro_movimento_idpk, body } = payload;

        try {
            const params = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                financeiro_movimento_idpk,
            };

            const url = buildUrl(Endpoint.FinanceiroMovimentoLancamento, params);
            const response: ApiResponse = await comTokenPost(url, body);
            throwIfResponseIsErr(response);

            dispatch.contasPagar.get({
                type: GetTypes.editRecord,
            });

            dispatch.contasPagar.setState({
                postLancamento: endReduxFnOk('success'),
            });
        } catch (error) {
            dispatch.contasPagar.setState({
                postLancamento: endReduxFnError(error),
            });
        }
    },

    async estornarLancamento(payload: {
        fml_idpk: number;
        fml_agrupado_id?: string;
        fmo_idpk?: number;
        data_estorno?: string;
    }): Promise<void> {
        dispatch.contasPagar.setState({
            estornarLancamento: startReduxFn(),
        });

        try {
            const { fml_idpk, fml_agrupado_id, fmo_idpk, data_estorno } = payload;

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                data_estorno,
            };

            let url = '';

            if (fml_agrupado_id) {
                params.grupo_id = fml_agrupado_id;
                url = buildUrl(Endpoint.FinanceiroMovimentoLancamentoEstornar, params);
            } else {
                url = buildUrl(Endpoint.FinanceiroMovimentoLancamentoEstornar, params, fml_idpk);
            }

            const response: ApiResponse = await comTokenPut(url);
            throwIfResponseIsErr(response);

            if (fmo_idpk) {
                dispatch.contasPagar.get({ type: GetTypes.firstGet });
            }

            dispatch.contasPagar.setState({
                estornarLancamento: endReduxFnOk(null),
            });
        } catch (error) {
            dispatch.contasPagar.setState({
                estornarLancamento: endReduxFnError(error),
            });
        }
    },

    async postAnexo(
        payload: {
            financeiro_movimento_idpk: number;
            files: ContasPagarFile[];
        },
        state: RootState,
    ): Promise<void> {
        try {
            const { financeiro_movimento_idpk, files } = payload;

            if (files.length === 0) {
                return;
            }

            dispatch.contasPagar.setState({
                postAnexo: startReduxFn(state.contasPagar.postAnexo.data),
            });

            const params = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                financeiro_movimento_idpk,
            };

            const url = buildUrl(Endpoint.FinanceiroMovimentoAnexo, params);
            const body = { registros: files };
            const response: ApiResponse = await comTokenPost(url, body);
            throwIfResponseIsErr(response);

            dispatch.contasPagar.setState({
                postAnexo: endReduxFnOk(null),
            });

            notification.success({
                message: 'Sucesso!',
                description: 'Anexos foram cadastrados',
            });
        } catch (error) {
            dispatch.contasPagar.setState({
                postAnexo: endReduxFnError(error),
            });

            notification.success({
                message: 'Falhou!',
                description: 'Falha ao cadastrar anexos',
            });
        }
    },

    async deleteAnexo(
        payload: {
            financeiro_movimento_idpk: number;
            anexo_idpk: number;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.contasPagar.setState({
            deleteAnexo: startReduxFn(state.contasPagar.deleteAnexo.data),
        });

        try {
            const { financeiro_movimento_idpk, anexo_idpk } = payload;

            const params = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                financeiro_movimento_idpk,
            };

            const url = buildUrl(Endpoint.FinanceiroMovimentoAnexo, params, anexo_idpk);
            const response: ApiResponse = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.contasPagar.setState({
                deleteAnexo: endReduxFnOk(null),
            });
        } catch (error) {
            dispatch.contasPagar.setState({
                deleteAnexo: endReduxFnError(error),
            });
        }
    },
});
