import { CaretDownOutlined } from '@ant-design/icons';
import { Image, Modal, Typography } from 'antd';
import { EmpresaSelector } from 'components/revisar/EmpresaSelector';
import { SwitchGridList } from 'components/revisar/EmpresaSelector/SwitchGridList';
import { useLoginStore } from 'features/login/store';
import { Fragment, useState } from 'react';
import { formatDocument } from 'std/format';
import { useScreenAliases } from 'std/hooks/useScreenAliases';

const { Text, Title } = Typography;

export function SelectEmpresas() {
    const { isSmallestMobile, isSmallerThanTablet } = useScreenAliases();

    const [showModal, setShowModal] = useState(false);

    const empresa_idpk = useLoginStore((s) => s.empresaIdpk);
    const empresas = useLoginStore((s) => s.login?.usuario_empresa) || [];

    const Container = ({ children }: { children: React.ReactNode }) => {
        return empresas.length > 1 ? (
            <div
                className='relative flex text-left border border-gray-100 border-solid cursor-pointer hover:border-primary btn hover:bg-gray-50'
                role='button'
                tabIndex={0}
                onClick={() => setShowModal(true)}
                onKeyDown={() => setShowModal(true)}
            >
                {children}
            </div>
        ) : (
            <div className='relative flex px-2 py-1 rounded'>{children}</div>
        );
    };

    return (
        <>
            <Container>
                {empresas
                    .filter((use) => use.use_empresa_idpk === empresa_idpk)
                    .map((use) => (
                        <Fragment key={use.use_idpk}>
                            {!isSmallestMobile && (
                                <Image
                                    src={use.empresa[0]?.emp_logotipo_foto || undefined}
                                    fallback={'resources/images/empresa_fallback.png'}
                                    style={{
                                        width: '2.5rem',
                                        height: '2.5rem',
                                    }}
                                    className='mt-1 ml-1 mr-2'
                                    rootClassName='flex justify-center'
                                    preview={false}
                                />
                            )}

                            <div
                                className='flex flex-col'
                                style={{
                                    maxWidth: 'calc(100% - 2.6rem)',
                                }}
                            >
                                <Title
                                    level={3}
                                    className='p-0 m-0 mr-1'
                                    style={{
                                        fontSize: isSmallestMobile ? 12 : 16,
                                        maxWidth: '100%',
                                    }}
                                    ellipsis={{ tooltip: use.empresa[0]?.emp_nome_razaosocial }}
                                >
                                    {use.empresa[0]?.emp_nome_razaosocial}
                                </Title>

                                <Title
                                    level={5}
                                    className='p-0 m-0 font-weight-normal font-italic text-slate-500'
                                    style={{ fontSize: isSmallestMobile ? 11 : 14 }}
                                >
                                    {use.empresa[0]?.emp_cnpj_cpf &&
                                        `${
                                            use.empresa[0]?.emp_tipo_pessoa === 'F' ? 'CPF' : 'CNPJ'
                                        }: ${formatDocument(use.empresa[0]?.emp_cnpj_cpf)}`}
                                </Title>
                                {empresas.length > 1 && (
                                    <CaretDownOutlined
                                        className='absolute'
                                        style={{ right: 8, bottom: 3 }}
                                    />
                                )}
                            </div>
                        </Fragment>
                    ))}
            </Container>

            <Modal
                open={showModal}
                width={isSmallerThanTablet ? '90vw' : '50vw'}
                closable={true}
                onCancel={() => setShowModal(false)}
                title={
                    <div>
                        <Text>Selecione a Empresa</Text>
                        <SwitchGridList style={{ right: 40, top: 14, zIndex: 999 }} />
                    </div>
                }
                footer={null}
            >
                <EmpresaSelector closeModal={() => setShowModal(false)} />
            </Modal>
        </>
    );
}
