import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { ExampleUsePaginationParams } from 'features/debug/page/pagination/ExampleUsePaginationParams';
import { defaultSearchParamsSchema } from 'std/api/types';
import { z } from 'zod';

const debugSchema = defaultSearchParamsSchema.extend({
    pesquisa_composta: z.string().catch(''),
});

export type DebugSchema = z.infer<typeof debugSchema>;

export const Route = createFileRoute('/a/debug/pagination')({
    component: ExampleUsePaginationParams,
    validateSearch: zodValidator(debugSchema),
});
