import dayjs from 'dayjs';
import DayjsDuration from 'dayjs/plugin/duration';
import DayjsUtc from 'dayjs/plugin/utc';
import { useLoginStore } from 'features/login/store';
import { base64ToStr } from 'std/base64';
import { DATE_TIME_FORMAT } from 'std/const/date';

// A quantidade de horas que leva pro token expirar
const TOKEN_EXPIRE_HOURS = 12;

export function isJwtValid(jwt: string): boolean {
    if (typeof jwt !== 'string') {
        return false;
    }

    try {
        const parts = jwt.split('.');

        if (parts.length !== 3) {
            return false;
        }

        const part = parts[1];

        if (!part) {
            return false;
        }

        const decoded = base64ToStr(part);
        JSON.parse(decoded);
    } catch (_e) {
        return false;
    }

    return true;
}

export function isTokenValid(): boolean {
    const token = useLoginStore.getState().login?.token;
    let token_exp = useLoginStore.getState().login?.token_exp;

    if (!(token && isJwtValid(token))) {
        return false;
    }

    const isValidDate = dayjs(token_exp, DATE_TIME_FORMAT, true).isValid();

    dayjs.extend(DayjsUtc);
    dayjs.extend(DayjsDuration);

    if (!isValidDate) {
        token_exp = dayjs().utc().format(DATE_TIME_FORMAT);
    }

    const nowString = dayjs().utc().format(DATE_TIME_FORMAT);
    const startDatetime = dayjs(token_exp, DATE_TIME_FORMAT);
    const endDatetime = dayjs(nowString, DATE_TIME_FORMAT);
    const duration = dayjs.duration(endDatetime.diff(startDatetime));
    const hours = duration.asHours();

    if (hours > TOKEN_EXPIRE_HOURS) {
        return false;
    }

    return true;
}
