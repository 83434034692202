import { notification } from 'antd';
import type { CardProperties } from 'components/revisar/CardList';
import type { Dayjs } from 'dayjs';
import { useLoginStore } from 'features/login/store';
import type { FormCompra } from 'pages/compras/forms/types';
import type { RootDispatch, RootState } from 'state/store';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import { isResponseErr, throwIfResponseIsErr } from 'std/api/util';
import { type BuildUrlParams, buildUrl } from 'std/api2/buildUrl';
import { openPDF } from 'std/pdf';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import type { ValorTotalItem } from 'std/types';
import { DefaultColors } from 'std/types/enum';
import { Endpoint } from 'std/types/enum/endpoint';
import type { SortParams } from 'std/types/interfaces';
import type { RelatoriosPayload } from 'std/types/interfaces/RelatoriosPayload';
import type { TCobrancaBoleto } from './types';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            status?: string;
            pesquisar?: string;
            cliente_idpk?: number;
            financeiro_conta_idpk?: number;
            usuario_idpk?: number;
            dataVencimento?: Interval<Dayjs>;
            dataEmissao?: Interval<Dayjs>;
            dataRecebimento?: Interval<Dayjs>;
            antecipacao?: number;
            total_registros?: string;
            registro_inicial?: number;
            qtde_registros?: number;
            sort?: SortParams;
            resetPagination?: boolean;
        },
        state: RootState,
    ): Promise<void> {
        const { cobrancaBoleto } = state;
        const { getTable, get } = cobrancaBoleto;

        dispatch.cobrancaBoleto.setState({
            get: startReduxFn(get.data),
            getTable: {
                ...getTable,
                updateTable: false,
            },
        });

        const {
            filterStatus,
            filterNome,
            filterCliente,
            filterUsuario,
            filterContaBancaria,
            filterDataRangeEmissao,
            filterDataRangeVencimento,
            filterDataRangeRecebimento,
            filterAntecipacao,
            filterExcluidos,
            registroInitial,
            qtdRegistros,
            sortParams,
            pagination,
        } = getTable;
        const {
            status,
            pesquisar,
            cliente_idpk,
            financeiro_conta_idpk,
            usuario_idpk,
            dataEmissao,
            dataRecebimento,
            antecipacao,
            total_registros,
            registro_inicial,
            qtde_registros,
            sort,
            resetPagination,
        } = payload;

        // MONTANDO OS PARAMETROS OBRIGATÓRIOS
        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            registro_inicial:
                registro_inicial !== null && registro_inicial !== undefined
                    ? registro_inicial
                    : registroInitial,
            qtde_registros: qtde_registros || qtdRegistros,
            orderby: 'fmb_idpk desc',
        };

        // CASO TIVER O FILTRO DE CLIENTE (*OPCIONAL)
        if (!!cliente_idpk || !!filterCliente) {
            params.cliente_idpk = cliente_idpk || filterCliente?.fmo_cliente_idpk;
        }

        // CASO TIVER O FILTRO DE CONTA BANCÁRIA (*OPCIONAL)
        if (!!financeiro_conta_idpk || !!filterContaBancaria) {
            params.financeiro_conta_idpk = financeiro_conta_idpk || filterContaBancaria?.fco_idpk;
        }

        // CASO TIVER O FILTRO DE USUÁRIO (*OPCIONAL)
        if (!!usuario_idpk || !!filterUsuario) {
            params.usuario_idpk = usuario_idpk || filterUsuario?.usu_idpk;
        }

        // CASO TIVER O FILTRO DE DATA DE VENCIMENTO (*OPCIONAL)
        if (!!dataRecebimento || !!filterDataRangeVencimento) {
            params.vencimento_de = (dataRecebimento || filterDataRangeVencimento)?.start.format(
                'DD-MM-YYYY',
            );
            params.vencimento_ate = (dataRecebimento || filterDataRangeVencimento)?.end.format(
                'DD-MM-YYYY',
            );
        }

        // CASO TIVER O FILTRO DE DATA DE EMISSÃO (*OPCIONAL)
        if (!!dataEmissao || !!filterDataRangeEmissao) {
            params.data_de = `${(dataEmissao || filterDataRangeEmissao)?.start.format(
                'DD-MM-YYYY',
            )} ${'00:00:01'}`;
            params.data_ate = `${(dataEmissao || filterDataRangeEmissao)?.end.format(
                'DD-MM-YYYY',
            )} ${'23:59:59'}`;
        }

        // CASO TIVER O FILTRO DE DATA DE RECEBIMENTO (*OPCIONAL)
        if (!!dataRecebimento || !!filterDataRangeRecebimento) {
            params.pagamento_de = `${(dataRecebimento || filterDataRangeRecebimento)?.start.format(
                'DD-MM-YYYY',
            )} ${'00:00:01'}`;
            params.pagamento_ate = `${(dataRecebimento || filterDataRangeRecebimento)?.end.format(
                'DD-MM-YYYY',
            )} ${'23:59:59'}`;
        }

        if (filterExcluidos) {
            params.excluidos = 'S';
        }

        // CASO TIVER O FILTRO DE TIPO (*OPCIONAL)
        if ((!!status && status !== 'Total') || (!!filterStatus && filterStatus !== 'Total')) {
            params.status = `["${status || filterStatus}"]`;
        }

        // CASO TIVER O FILTRO DE NOME/TEXTO (*OPCIONAL)
        if (!!pesquisar || !!filterNome) {
            params.cliente = pesquisar || filterNome;
        }

        // CASO TIVER O FILTRO DE ANTECIPAÇÃO (*OPCIONAL)
        if (!!antecipacao || !!filterAntecipacao) {
            params.antecipacao_status = antecipacao || filterAntecipacao;
        }

        // CASO TER QUE FILTRAR (SORTEAR) OS DADOS (*OPCIONAL)
        if (sort?.shouldSort || sortParams?.shouldSort) {
            params.orderby = `${sort?.fieldName || sortParams?.fieldName}${
                sort?.orderDirection || sortParams?.orderDirection
            }`;
        }

        // CASO TIVER ENVIADO PARA MOSTRAR TODOS REGISTROS
        if (total_registros) {
            params.total_registros = total_registros;
        }

        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
        if (resetPagination) {
            params.registro_inicial = 0;
            params.total_registros = 'S';
        }

        const url = buildUrl(Endpoint.FinanceiroMovimentoBoleto, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [], total_registros: totalRegistroResponse = null } = {},
            } = response;

            dispatch.cobrancaBoleto.setState({
                get: endReduxFnOk(registros),
                getTable: {
                    ...getTable,
                    filterStatus: status || filterStatus,
                    ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                        totalRegistrosTable: totalRegistroResponse,
                    }),
                    registro_inicial:
                        registro_inicial !== null && registro_inicial !== undefined
                            ? registro_inicial
                            : registroInitial,
                    // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                    ...(resetPagination && {
                        registro_inicial: 0,
                    }),
                    qtde_registros: qtde_registros || qtdRegistros,
                    pagination: {
                        ...pagination,
                        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                        ...(resetPagination && {
                            current: 1,
                        }),
                        // SE PRECISAR ATUALIZAR A PÁGINA É FEITO AQUI
                        ...(registro_inicial !== null &&
                            registro_inicial !== undefined && {
                                current: registro_inicial / (qtde_registros || qtdRegistros) + 1,
                            }),
                        // SE PRECISAR ATUALIZAR OS TOTAIS É FEITO AQUI
                        ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                            total: totalRegistroResponse,
                            showTotal: () => `Total de Registros: ${totalRegistroResponse}`,
                            showSizeChanger: totalRegistroResponse > 10,
                        }),
                    },
                },
            });
        } catch (error) {
            dispatch.cobrancaBoleto.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async totalizador(_, state: RootState): Promise<void> {
        const { cobrancaBoleto } = state;
        const { totalizador, getTable } = cobrancaBoleto;

        dispatch.cobrancaBoleto.setState({
            totalizador: startReduxFn(totalizador.data),
        });

        const {
            filterNome,
            filterCliente,
            filterUsuario,
            filterContaBancaria,
            filterDataRangeEmissao,
            filterDataRangeVencimento,
            filterDataRangeRecebimento,
            filterAntecipacao,
            filterExcluidos,
        } = getTable;

        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
        };

        // CASO TIVER O FILTRO DE NOME/TEXTO (*OPCIONAL)
        if (filterNome) {
            params.cliente = filterNome;
        }

        // CASO TIVER O FILTRO DE CLIENTE (*OPCIONAL)
        if (filterCliente) {
            params.cliente_idpk = filterCliente?.fmo_cliente_idpk;
        }

        // CASO TIVER O FILTRO DE CONTA BANCÁRIA (*OPCIONAL)
        if (filterContaBancaria) {
            params.financeiro_conta_idpk = filterContaBancaria?.fco_idpk;
        }

        // CASO TIVER O FILTRO DE USUÁRIO (*OPCIONAL)
        if (filterUsuario) {
            params.usuario_idpk = filterUsuario?.usu_idpk;
        }

        // CASO TIVER O FILTRO DE DATA DE VENCIMENTO (*OPCIONAL)
        if (filterDataRangeVencimento) {
            params.vencimento_de = filterDataRangeVencimento?.start.format('DD-MM-YYYY');
            params.vencimento_ate = filterDataRangeVencimento?.end.format('DD-MM-YYYY');
        }

        // CASO TIVER O FILTRO DE DATA DE EMISSÃO (*OPCIONAL)
        if (filterDataRangeEmissao) {
            params.data_de = `${filterDataRangeEmissao?.start.format('DD-MM-YYYY')} ${'00:00:01'}`;
            params.data_ate = `${filterDataRangeEmissao?.end.format('DD-MM-YYYY')} ${'23:59:59'}`;
        }

        // CASO TIVER O FILTRO DE DATA DE RECEBIMENTO (*OPCIONAL)
        if (filterDataRangeRecebimento) {
            params.pagamento_de = `${filterDataRangeRecebimento?.start.format(
                'DD-MM-YYYY',
            )} ${'00:00:01'}`;
            params.pagamento_ate = `${filterDataRangeRecebimento?.end.format(
                'DD-MM-YYYY',
            )} ${'23:59:59'}`;
        }

        if (filterExcluidos) {
            params.excluidos = 'S';
        }

        // CASO TIVER O FILTRO DE ANTECIPAÇÃO (*OPCIONAL)
        if (filterAntecipacao) {
            params.antecipacao_status = filterAntecipacao;
        }

        const url = buildUrl(Endpoint.FinanceiroMovimentoBoletoValoresTotais, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            const cores = {
                Incorreto: DefaultColors.Orange,
                Vencido: DefaultColors.Black,
                Aguardando: DefaultColors.Blue,
                Liquidado: DefaultColors.Green,
                Total: DefaultColors.Purple,
                'Liquidado Pix': DefaultColors.DarkGreen,
                'Devolvido Pix': DefaultColors.Red,
                Baixado: DefaultColors.Gray,
            };

            const totais: CardProperties[] = registros.map((total: ValorTotalItem) => ({
                color: cores[total?.status] || DefaultColors.Black,
                title: total.status?.[0]
                    ? total.status[0].toUpperCase() + total.status.slice(1)
                    : '',
                amount: total?.valor_total || 0,
                number: total?.quantidade_total || 0,
                value: total?.status || '',
            }));

            dispatch.cobrancaBoleto.setState({
                totalizador: endReduxFnOk(totais),
            });
        } catch (error) {
            dispatch.cobrancaBoleto.setState({
                totalizador: endReduxFnError(error),
            });
        }
    },

    async getOne(payload: { fmb_idpk: number }, state: RootState): Promise<void> {
        const { cobrancaBoleto } = state;
        const { getOne } = cobrancaBoleto;

        dispatch.cobrancaBoleto.setState({
            getOne: startReduxFn(getOne?.data),
        });

        const { fmb_idpk } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.FinanceiroMovimentoBoleto, params, fmb_idpk);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            dispatch.cobrancaBoleto.setState({
                getOne: endReduxFnOk(registros && registros.length > 0 ? registros[0] : null),
            });
        } catch (error) {
            dispatch.cobrancaBoleto.setState({
                getOne: endReduxFnError(error),
            });
        }
    },

    async post(payload: { body: TCobrancaBoleto }, state: RootState): Promise<void> {
        const { cobrancaBoleto } = state;
        const { post } = cobrancaBoleto;

        dispatch.cobrancaBoleto.setState({
            post: startReduxFn(post?.data),
        });

        const { body } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.FinanceiroMovimentoBoleto, params);

        try {
            const response = await comTokenPost(url, body);

            if (isResponseErr(response)) {
                // Colocado aqui para inserir o ERROR para pegar os dados personalizados
                dispatch.cobrancaBoleto.setState({
                    post: endReduxFnError(response.data),
                });

                throw new Error(response.data?.mensagem || '');
            }

            const fmb_idpk: number = response?.data?.registros
                ? response.data.registros[0].fmb_idpk
                : 0;

            dispatch.cobrancaBoleto.setState({
                post: endReduxFnOk({ fmb_idpk }),
            });

            notification.success({
                message: 'Feito!',
                description: 'Boleto cadastrado',
            });
        } catch (error) {
            notification.error({
                message: 'Não foi possível cadastrar o boleto!',
                description: error.message,
            });
        }
    },

    async put(payload: { fmb_idpk: number; body: FormCompra }, state: RootState): Promise<void> {
        const { cobrancaBoleto } = state;
        const { put } = cobrancaBoleto;

        dispatch.cobrancaBoleto.setState({
            put: startReduxFn(put?.data),
        });

        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
        const { fmb_idpk = 0, body } = payload;

        const url = buildUrl(Endpoint.FinanceiroMovimentoBoleto, params, fmb_idpk);

        try {
            const response = await comTokenPut(url, body);

            if (isResponseErr(response)) {
                // Colocado aqui para inserir o ERROR para pegar os dados personalizados
                dispatch.cobrancaBoleto.setState({
                    put: endReduxFnError(response.data),
                });

                throw new Error(response.data?.mensagem || '');
            }

            dispatch.cobrancaBoleto.setState({
                put: endReduxFnOk('Success'),
            });

            notification.success({
                message: 'Feito!',
                description: 'Boleto atualizado',
            });
        } catch (error) {
            notification.error({
                message: 'Não foi possível atualizar o boleto!',
                description: error.message,
            });
        }
    },

    async remove(
        payload: {
            fmb_idpk: number;
            updateTable?: boolean;
            successDescription: string;
        },
        state: RootState,
    ): Promise<void> {
        const { cobrancaBoleto } = state;
        const { getTable, remove } = cobrancaBoleto;

        dispatch.cobrancaBoleto.setState({
            remove: startReduxFn(remove?.data),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { fmb_idpk = 0, updateTable, successDescription } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.FinanceiroMovimentoBoleto, params, fmb_idpk);

        try {
            const response = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.cobrancaBoleto.setState({
                remove: endReduxFnOk('Success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                const {
                    pagination,
                    pagination: { total = 0 },
                    registroInitial = 0,
                } = getTable;

                let isLastPageOnlyOneRegister = false;

                // VERIFICA SE É A ÚLTIMA PÁGINA E TEM APENAS UM ITEM PARA PODER MUDAR DE PÁGINA APÓS DELETAR
                if (total && registroInitial && total - 1 === registroInitial) {
                    isLastPageOnlyOneRegister = true;
                    dispatch.cobrancaBoleto.get({
                        total_registros: 'S',
                        registro_inicial: registroInitial - (pagination?.pageSize || 0),
                    });

                    dispatch.cobrancaBoleto.totalizador({});
                }

                if (!isLastPageOnlyOneRegister) {
                    dispatch.cobrancaBoleto.get({ total_registros: 'S' });
                    dispatch.cobrancaBoleto.totalizador({});
                }
            }

            notification.success({
                message: 'Feito!',
                description: successDescription,
            });
        } catch (error) {
            dispatch.cobrancaBoleto.setState({
                remove: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async gerarListaPDFs(payload: { tipoPagto: 'P' | 'C' }, state: RootState): Promise<void> {
        const { cobrancaBoleto } = state;
        const { getTable } = cobrancaBoleto;

        dispatch.cobrancaBoleto.setState({
            gerarListaPDFs: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { tipoPagto } = payload;
        const { selectedRows } = getTable;

        const registros_list = selectedRows.map((row) => row.key);

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            registros_list: JSON.stringify(registros_list),
            tipo: tipoPagto,
        };

        const url = buildUrl(Endpoint.FinanceiroMovimentoBoletoGerarListaPDFs, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            // payload vem diferente de acordo com nº de registros
            if (selectedRows.length === 1) {
                openPDF({
                    access: 'autenticado',
                    type: 'link',
                    link: response.data.link_url,
                });
            } else {
                openPDF({
                    access: 'autenticado',
                    type: 'file',
                    relatorios: response.data as RelatoriosPayload,
                });
            }

            dispatch.cobrancaBoleto.setState({
                gerarListaPDFs: endReduxFnOk('Success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        } catch (error) {
            dispatch.cobrancaBoleto.setState({
                gerarListaPDFs: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        }
    },

    async consultarSituacaoBoleto(
        payload: { fmb_idpk?: number; flgUpdate?: boolean },
        state: RootState,
    ): Promise<void> {
        const { cobrancaBoleto } = state;
        const { getTable } = cobrancaBoleto;

        dispatch.cobrancaBoleto.setState({
            consultarSituacaoBoleto: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { fmb_idpk = 0, flgUpdate = true } = payload;

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
        };

        const url = buildUrl(Endpoint.FinanceiroMovimentoBoletoConsultarSituacao, params, fmb_idpk);

        try {
            const response = await comTokenPost(url);
            throwIfResponseIsErr(response);

            dispatch.cobrancaBoleto.setState({
                consultarSituacaoBoleto: endReduxFnOk('Success'),
            });

            if (flgUpdate) {
                dispatch.cobrancaBoleto.get({ total_registros: 'S' });
            }
        } catch (error) {
            dispatch.cobrancaBoleto.setState({
                consultarSituacaoBoleto: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        }
    },

    async getRetornosBancarios(payload: {
        financeiro_conta_idpk: number;
    }): Promise<void> {
        dispatch.cobrancaBoleto.setState({
            getRetornosBancarios: startReduxFn(),
        });

        const { financeiro_conta_idpk = 0 } = payload;

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            financeiro_conta_idpk,
            orderby: 'fcr_data_processamento desc',
        };

        const url = buildUrl(Endpoint.FinanceiroContaRetornoBuscar, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            dispatch.cobrancaBoleto.setState({
                getRetornosBancarios: endReduxFnOk(
                    response?.data?.registros ? response.data.registros : null,
                ),
            });
        } catch (error) {
            dispatch.cobrancaBoleto.setState({
                getRetornosBancarios: endReduxFnError(error),
            });
        }
    },

    async postRetornosBancarios(payload: {
        financeiro_conta_idpk: number;
        periodo_de?: string;
        periodo_ate?: string;
    }): Promise<void> {
        dispatch.cobrancaBoleto.setState({
            postRetornosBancarios: startReduxFn(),
        });

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            ...payload,
        };

        const url = buildUrl(Endpoint.FinanceiroContaRetornoProcessarRetornoBancario, params);

        try {
            const response = await comTokenPost(url);
            throwIfResponseIsErr(response);

            dispatch.cobrancaBoleto.setState({
                postRetornosBancarios: endReduxFnOk(
                    response?.data?.registros ? response.data.registros : null,
                ),
            });
        } catch (error) {
            dispatch.cobrancaBoleto.setState({
                postRetornosBancarios: endReduxFnError(error),
            });

            notification.error({
                message: 'Sem resultado!',
                description: error.message,
            });
        }
    },
});
