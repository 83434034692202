import { createRoot } from 'react-dom/client';
import { App } from './App/App';
import './globalStyle.scss';
import 'core-js/proposals/promise-with-resolvers';
import { initBeforeReact } from 'initBeforeReact';
import { StrictMode } from 'react';

initBeforeReact();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
    <StrictMode>
        <App />
    </StrictMode>,
);
